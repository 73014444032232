import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { Title } from "@components/styles";
import BasicInfo from "./BasicInfo";
import Thumbnail from "./Thumbnail";
import FilterPrice from "./FilterPrice";
import FavoriteReport from "./FavoriteReport";
import ConfirmButton from "./ConfirmButton";

import { useGetReportDetail } from "@hooks/admin";
import { useGetDanjiPyeongPriceInfoList } from "@hooks/imjang";

export default function EditReport() {
  const params = useParams();
  const { mutate: 레포트상세조회 } = useGetReportDetail();
  const [reportInfo, setReportInfo] = useState({
    page_url: "",
    title: "",
    influencer: null,
    new_reports_desc: null,
    consulting_id: null,
    danji_list: null,
    release_date: null,
    kakao_shared_thumbnail: { fileName: "", file: null, preview: null },
    thumbnail_80: { fileName: "", file: null, preview: null },
    thumbnail_120: { fileName: "", file: null, preview: null },
    thumbnail_327: { fileName: "", file: null, preview: null },
    filter_tags: [],
    payment_type: null,
    coin_amount: null,
    push_title: null,
    push_body: null,
    push_schedule: null,
    push_target: null,
    new_report_start_date: null,
    new_report_end_date: null,
  });
  const [additional, setAdditional] = useState(undefined);
  const [isFavoriteActive, setIsFavoriteActive] = useState(false);
  const { mutate: getDanjiPyeongPriceInfo } = useGetDanjiPyeongPriceInfoList();

  useEffect(() => {
    if (params.id) {
      레포트상세조회(
        { content_id: Number(params.id) },
        {
          onSuccess(res) {
            const copied = { ...res };

            copied.kakao_shared_thumbnail = {
              fileName: copied.kakao_shared_thumbnail,
              file: null,
              preview: null,
            };
            copied.thumbnail_80 = {
              fileName: copied.thumbnail_80,
              file: null,
              preview: null,
            };
            copied.thumbnail_120 = {
              fileName: copied.thumbnail_120,
              file: null,
              preview: null,
            };
            copied.thumbnail_327 = {
              fileName: copied.thumbnail_327,
              file: null,
              preview: null,
            };

            if (!copied.filter_tags.includes("전체")) {
              copied.filter_tags = ["전체", ...copied.filter_tags];
            }

            setReportInfo(copied);

            if (copied.new_report_start_date) {
              setIsFavoriteActive(true);
            }

            if (copied.consulting_id) {
              setAdditional("consulting_id");
            } else if (copied.danji_list?.length > 0) {
              setAdditional("danji_list");
            } else {
              setAdditional("none");
            }

            if (copied.danji_list?.length > 0) {
              getDanjiPyeongPriceInfo(copied.danji_list, {
                onSuccess(res) {
                  setReportInfo((prev) => ({ ...prev, danji_list: res }));
                },
              });
            }
          },
          onError(err) {
            alert(`데이터 조회에 실패했습니다.`);
          },
        }
      );
    }
  }, [params]);

  return (
    <Container>
      <Title>주간 레포트 수정</Title>
      <BasicInfo
        contentId={Number(params.id)}
        reportInfo={reportInfo}
        setReportInfo={setReportInfo}
        additional={additional}
        setAdditional={setAdditional}
      />
      <Thumbnail reportInfo={reportInfo} setReportInfo={setReportInfo} />
      <FilterPrice reportInfo={reportInfo} setReportInfo={setReportInfo} />
      <FavoriteReport
        reportInfo={reportInfo}
        setReportInfo={setReportInfo}
        isFavoriteActive={isFavoriteActive}
        setIsFavoriteActive={setIsFavoriteActive}
      />
      <ConfirmButton
        contentId={Number(params.id)}
        reportInfo={reportInfo}
        additional={additional}
        isFavoriteActive={isFavoriteActive}
      />
    </Container>
  );
}

const Container = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;
