import { useState } from "react";
import styled from "styled-components";

import AddImage from "../AddImage";
import Section from "../Section";

import { Arrows22 } from "@components/core/icon/arrow";
import {
  ArrowDirectionEnum,
  ArrowColorEnum,
} from "@components/core/icon/arrow/Arrow.interfaces";

export default function Thumbnail({ reportInfo, setReportInfo }) {
  const [isShown, setIsShown] = useState(false);

  const updateImg = (key: string, event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();

      setReportInfo((prev) => ({
        ...prev,
        [key]: { file: selectedFile, fileName: selectedFile.name },
      }));

      // FileReader를 사용하여 미리보기 생성

      reader.onload = (e) => {
        setReportInfo((prev) => ({
          ...prev,
          [key]: {
            file: selectedFile,
            fileName: selectedFile.name,
            preview: e.target.result,
          },
        }));
      };
      reader.readAsDataURL(selectedFile); // 파일을 base64로 읽음
    }
  };

  const deleteImg = (key: string) => {
    setReportInfo((prev) => ({
      ...prev,
      [key]: { file: null, fileName: "", preview: null },
    }));
  };

  return (
    <Container>
      <TitleBox onClick={() => setIsShown(!isShown)}>
        <div style={{ display: "flex" }}>
          <h3>썸네일</h3>
          <Star />
        </div>
        <Arrows22
          direction={isShown ? ArrowDirectionEnum.UP : ArrowDirectionEnum.DOWN}
          color={ArrowColorEnum.B50}
        />
      </TitleBox>
      {isShown && (
        <InputContainer>
          <Section
            title="카카오 공유"
            bodyComponent={
              <ImageContainer>
                {(reportInfo.kakao_shared_thumbnail.preview ||
                  reportInfo.kakao_shared_thumbnail.fileName) && (
                  <img
                    src={
                      reportInfo.kakao_shared_thumbnail.preview ||
                      reportInfo.kakao_shared_thumbnail.fileName
                    }
                    alt="카카오 공유 미리보기"
                    style={{
                      marginRight: 10,
                      width: "auto",
                      maxHeight: "50px",
                      borderRadius: "10px",
                    }}
                  />
                )}
                <AddImage
                  updateImg={(e) => updateImg("kakao_shared_thumbnail", e)}
                  fileName={reportInfo.kakao_shared_thumbnail.fileName}
                  deleteImg={() => deleteImg("kakao_shared_thumbnail")}
                />
              </ImageContainer>
            }
          />
          <Section
            title="th_80"
            bodyComponent={
              <ImageContainer>
                {(reportInfo.thumbnail_80.preview ||
                  reportInfo.thumbnail_80.fileName) && (
                  <img
                    src={
                      reportInfo.thumbnail_80.preview ||
                      reportInfo.thumbnail_80.fileName
                    }
                    alt="th_80 미리보기"
                    style={{
                      marginRight: 10,
                      width: "auto",
                      maxHeight: "50px",
                      borderRadius: "10px",
                    }}
                  />
                )}
                <AddImage
                  updateImg={(e) => updateImg("thumbnail_80", e)}
                  fileName={reportInfo.thumbnail_80.fileName}
                  deleteImg={() => deleteImg("thumbnail_80")}
                />
              </ImageContainer>
            }
          />
          <Section
            title="th_120"
            bodyComponent={
              <ImageContainer>
                {(reportInfo.thumbnail_120.preview ||
                  reportInfo.thumbnail_120.fileName) && (
                  <img
                    src={
                      reportInfo.thumbnail_120.preview ||
                      reportInfo.thumbnail_120.fileName
                    }
                    alt="th_120 미리보기"
                    style={{
                      marginRight: 10,
                      width: "auto",
                      maxHeight: "50px",
                      borderRadius: "10px",
                    }}
                  />
                )}
                <AddImage
                  updateImg={(e) => updateImg("thumbnail_120", e)}
                  fileName={reportInfo.thumbnail_120.fileName}
                  deleteImg={() => deleteImg("thumbnail_120")}
                />
              </ImageContainer>
            }
          />
          <Section
            title="th_327"
            bodyComponent={
              <ImageContainer>
                {(reportInfo.thumbnail_327.preview ||
                  reportInfo.thumbnail_327.fileName) && (
                  <img
                    src={
                      reportInfo.thumbnail_327.preview ||
                      reportInfo.thumbnail_327.fileName
                    }
                    alt="th_327 미리보기"
                    style={{
                      marginRight: 10,
                      width: "auto",
                      maxHeight: "50px",
                      borderRadius: "10px",
                    }}
                  />
                )}
                <AddImage
                  updateImg={(e) => updateImg("thumbnail_327", e)}
                  fileName={reportInfo.thumbnail_327.fileName}
                  deleteImg={() => deleteImg("thumbnail_327")}
                />
              </ImageContainer>
            }
          />
        </InputContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  border: 1px solid #e5e7e9;
  border-radius: 16px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  height: 64px;
  padding: 19px 16px;

  h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #333e4d;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Star = styled.p`
  margin: 4px 0 0 2px;

  &::after {
    color: #f04452;
    content: "*";
  }
`;

const InputContainer = styled.div`
  border-top: 1px solid #e5e7e9;
  padding-bottom: 8px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
`;
