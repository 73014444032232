import styled from "styled-components";

import { Arrows22 } from "@components/core/icon/arrow";
import { ArrowDirectionEnum } from "@components/core/icon/arrow/Arrow.interfaces";
import { deeplinkList } from "@constants/deeplink";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { findSelectedOption } from "@utils/select";
import { useGetConsultingListWithoutVersion } from "@hooks/consultation";

const etcGroup = {
  group: "기타",
  contents: [
    {
      title: "외부 url",
      value: "external-url",
      requiresId: false,
    },
  ],
};

export default function MovingSelect({ modalInfo, setModalInfo }) {
  const { data } = useGetConsultingListWithoutVersion();
  const [inputActive, setInputActive] = useState({
    id: false,
    url: false,
  });

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setModalInfo((prev) => ({
      ...prev,
      deeplink: selectedValue,
      deeplinkId: "",
      externalUrl: "",
    }));
  };

  const handleUrl = (event: ChangeEvent<HTMLInputElement>) => {
    const url = event.target.value;
    setModalInfo((prev) => ({ ...prev, externalUrl: url }));
  };

  const handleId = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const id = event.target.value;

    setModalInfo((prev) => ({ ...prev, deeplinkId: id }));
  };

  const isConsulting = useMemo(() => {
    const consultingDetailValue = deeplinkList
      .find((group) => group.group === "서비스")
      ?.contents.find((content) => content.title === "컨설팅 상세")?.value;
    return modalInfo.deeplink === consultingDetailValue;
  }, [modalInfo.deeplink]);

  useEffect(() => {
    if (modalInfo.deeplink) {
      if (isConsulting) {
        setInputActive((prev) => ({ ...prev, id: false }));
        return;
      }
      const selectedObj = findSelectedOption(deeplinkList, modalInfo.deeplink);
      if (selectedObj.requiresId) {
        setInputActive((prev) => ({ ...prev, id: true }));
      } else {
        setInputActive((prev) => ({ ...prev, id: false }));
      }
    }

    if (modalInfo.deeplink === "external-url") {
      setInputActive((prev) => ({ ...prev, url: true }));
    } else {
      setInputActive((prev) => ({ ...prev, url: false }));
    }
  }, [modalInfo.deeplink, isConsulting]);

  return (
    <Container>
      <div className="select">
        <InputContainer>
          <select
            onChange={(e) => onChange(e)}
            style={{
              color: modalInfo.deeplink ? "#333e4d" : "#808690",
            }}
            value={modalInfo.deeplink}
          >
            <option value={undefined}>이동 할 화면을 설정해주세요</option>
            {[...deeplinkList, etcGroup].map((el, index) => (
              <optgroup label={el.group} key={index}>
                {el.contents.map((el, index) => (
                  <option value={el.value} label={el.title} key={index} />
                ))}
              </optgroup>
            ))}
          </select>
          <div style={{ position: "absolute", right: 16, top: 10 }}>
            <Arrows22 direction={ArrowDirectionEnum.DOWN} />
          </div>
        </InputContainer>
        <InputContainer
          style={{ backgroundColor: isConsulting ? "#f4f6f8" : "#E5E7E9" }}
        >
          <select
            disabled={!isConsulting}
            onChange={handleId}
            value={modalInfo.deeplinkId || undefined}
            style={{ color: modalInfo.deeplinkId ? "#333e4d" : "#808690" }}
          >
            <option value={undefined}>세부 스크린 설정</option>
            {data?.consultings.map((el, index) => {
              return (
                <option
                  value={el.consultingId}
                  label={el.influencer.name}
                  key={index}
                />
              );
            })}
          </select>
        </InputContainer>
        {/* 관리자가 컨설팅ID를 가져오기 어려움이 있어 이동 화면이 '컨설팅 상세'인 경우에만 드롭다운으로 관리자 목록을 보여줌 */}
      </div>
      {inputActive.id && (
        <div className="url">
          <Input
            placeholder={
              modalInfo.deeplink?.includes("report")
                ? "레포트ID를 입력하세요(숫자만 입력)"
                : "단지 코드를 입력하세요(숫자만 입력)"
            }
            disabled={!inputActive.id}
            value={modalInfo.deeplinkId || undefined}
            onChange={handleId}
          />
        </div>
      )}
      {inputActive.url ? (
        <div className="url">
          <Input
            placeholder="URL을 입력하세요"
            value={modalInfo.externalUrl}
            onChange={handleUrl}
            disabled={!inputActive.url}
          />
        </div>
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .select {
    display: flex;
    gap: 16px;
  }

  .url {
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 15px;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;

  select {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
    width: 100%;
    border: none;
    line-height: 24px; /* 160% */
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
    appearance: none;

    &:focus {
      outline: none;
    }
    &:disabled {
      background: ${({ theme }) => theme.colors.blueGray100};
      cursor: not-allowed;
    }
  }
`;

const Input = styled.input`
  padding: 12px 16px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  overflow: hidden;
  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 15px;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
  background: #f4f6f8;

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;
