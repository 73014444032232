import styled from "styled-components";

const SwitchButton = ({
  active,
  handleToggle,
  onColor = "#1C84FF",
}: {
  active: boolean;
  handleToggle: () => void;
  onColor?: string;
}) => {
  return (
    <SwitchContainer>
      <HiddenCheckbox
        checked={active}
        onChange={handleToggle}
        type="checkbox"
        id="styled-switch"
      />
      <SwitchLabel $active={active} $onColor={onColor} htmlFor="styled-switch">
        <Button $active={active} />
      </SwitchLabel>
    </SwitchContainer>
  );
};

export default SwitchButton;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchLabel = styled.label<{ $active?: boolean; $onColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 40px;
  height: 24px;
  background: ${(props) => (props.$active ? props.$onColor : "#ccc")};
  border-radius: 24px;
  position: relative;
  transition: background-color 0.2s;
`;

const Button = styled.span<{ $active?: boolean }>`
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: ${(props) =>
    props.$active ? "translateX(16px)" : "translateX(0)"};
`;

const HiddenCheckbox = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;
