// /push/get_user_inquiry?from=0&limit=20

import { Instance } from "@api/index";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Inquiry, InquiryReq, InquiryRes } from "./qna.interfaces";

export const useGetInquiry = ({ offset, limit, state }) =>
  useQuery<InquiryRes>({
    queryKey: ["inquiry", offset, limit, state],
    queryFn: async () => {
      const response = await Instance({
        url: `/push/get_user_inquiry?from=${offset}&limit=${limit}&state=${state}`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 1000 * 60,
  });

export const useGetInquiryById = ({ id }) =>
  useQuery({
    queryKey: ["inquiry", id],
    queryFn: async () => {
      const response = await Instance({
        url: `/push/get_user_inquiry_by_id/${id}`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 1000 * 60,
  });

export const usePostInquiryAnswer = () => {
  const queryClient = useQueryClient();
  return useMutation<number, AxiosError, InquiryReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: "/push/update_user_inquiry",
        method: "POST",
        data,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["inquiry"],
      });
    },
  });
};

export const useGetPushAgreeCheck = () =>
  useMutation<{ consent: boolean }, AxiosError, { userId: string }>({
    mutationFn: async ({ userId }) => {
      const response = await Instance({
        url: `/push/get_user_push_consent/${userId}`,
        method: "GET",
      });
      return response.data;
    },
    onSuccess: () => {},
  });

// /push/get_user_push_consent/
