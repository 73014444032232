import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  getCoreRowModel,
} from "@tanstack/react-table";
import { HTMLProps, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import "@components/table.css";
import Pagination from "@components/core/paging/Pagination";
import { useNavigate } from "react-router-dom";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import { Link } from "react-router-dom";
import Empty from "@components/common/Empty";

export default function SourceListTable({ data, setSelectedRow }) {
  const navigate = useNavigate();

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("checkBox", {
        cell: ({ row, column }) => {
          return (
            <IndeterminateCheckbox
              key={row.original["id"]}
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                onChange: row.getToggleSelectedHandler(),
                setSelectedRow: setSelectedRow,
                contentsId: row.original["contentsId"],
              }}
            />
          );
        },
        header: "",
        maxSize: 5,
      }),
      columnHelper.accessor("title", {
        cell: ({ row }) => {
          const goViewSource = () => {
            navigate(row.original["contentsId"]);
          };
          return <Title onClick={goViewSource}> {row.original["title"]}</Title>;
        },
        header: "제목",
        maxSize: 70,
      }),

      columnHelper.accessor("createdAt", {
        cell: ({ getValue }) => {
          return (
            <p style={{ textAlign: "center" }}>
              {convertToKoreanDate(getValue())}
            </p>
          );
        },
        header: "등록 날짜",
        maxSize: 15,
      }),
      columnHelper.accessor("contentsId", {
        cell: ({ getValue }) => {
          return (
            <StyledLink
              to={`edit/${getValue()}`}
              style={{ textAlign: "center" }}
            >
              <p>수정하기</p>
            </StyledLink>
          );
        },
        header: "정보수정",
        maxSize: 10,
      }),
    ];
  }, []);

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  return (
    <Container>
      <table className="contentsTable">
        <thead className="contentsThead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="noticeTableTd">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Empty data={data} title="데이터 자료가 없어요" />
      <Pagination table={table} />
    </Container>
  );
}

function IndeterminateCheckbox({
  className = "",
  onChange,
  setSelectedRow,
  contentsId,
  ...rest
}: {
  setSelectedRow?: React.Dispatch<React.SetStateAction<string[]>>;
  contentsId?: string;
} & HTMLProps<HTMLInputElement>) {
  const onClick = (e) => {
    onChange(e);

    setSelectedRow((prev) => {
      if (prev.includes(contentsId)) {
        const arr = [...prev];
        const index = arr.indexOf(contentsId);
        arr.splice(index, 1);
        return arr;
      } else {
        return [...prev, contentsId];
      }
    });
  };

  return (
    <input
      type="checkbox"
      className={className + " cursor-pointer"}
      onChange={onClick}
      {...rest}
    />
  );
}

const Container = styled.div`
  /* padding-top: 80px; */
`;

const Title = styled.h2`
  text-align: left;
  padding: 16px 0;
  cursor: pointer;
  max-width: 60vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryBlue100};
`;
