import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import { ButtonBox } from "@components/styles";
import { convertToKoreanTimeFromUTC } from "@hooks/calc/converToKoreanDate";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function QnaInput({ qnaInfo, setQnaInfo, data, handleConfirm }) {
  const navigate = useNavigate();

  return (
    <Container>
      <div className="table">
        <TRow>
          <TData>
            <div className="head">
              <p>문의 시간</p>
            </div>
            <div className="body">
              <p>{convertToKoreanTimeFromUTC(data.created_at)}</p>
            </div>
          </TData>
          <TData>
            <div className="head">
              <p>유저 정보</p>
            </div>
            <div className="body">
              <p>
                {data.nickname || "- "} ({data.userId})
              </p>
            </div>
          </TData>
        </TRow>
        <TRow>
          <TData>
            <div className="head">
              <p>문의 내용</p>
            </div>
            <div className="body" style={{ width: "100%" }}>
              <p> {data.question}</p>
            </div>
          </TData>
        </TRow>
        <TRow>
          <TData>
            <div className="head" style={{ alignItems: "flex-end" }}>
              <p>답변</p>
            </div>
            <div className="body">
              <BTypeTextArea
                placeholder={
                  data.nickname
                    ? "문의 내용에 대한 답변을 작성해주세요"
                    : "유저 정보가 없어서 답변을 입력할 수 없어요."
                }
                value={qnaInfo.answer}
                disabled={!data.nickname}
                onChangeValue={(v) =>
                  setQnaInfo((prev) => ({
                    ...prev,
                    answer: v,
                  }))
                }
                textareaStyle={{ height: 130, fontSize: 15 }}
              />
            </div>
          </TData>
        </TRow>
      </div>
      <ButtonBox style={{ marginTop: 20 }}>
        <button onClick={() => navigate(-1)}>취소</button>
        {data.nickname && (
          <button
            className={!qnaInfo.answer && "disabled"}
            disabled={!qnaInfo.answer}
            onClick={handleConfirm}
          >
            답글 달기
          </button>
        )}
      </ButtonBox>
    </Container>
  );
}

const Container = styled.div`
  .table {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    padding: 14px 0 28px;
    border-top: 2px solid ${({ theme }) => theme.colors.blueGray800};
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray100};
    gap: 20px;
  }

  .row {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    gap: 12px;

    h3 {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 18px;
      font-weight: 600;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.36px;
    }

    p {
      color: ${({ theme }) => theme.colors.red1000};
      font-size: 15px;
      font-weight: 400;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.3px;
    }
  }
`;

const TRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 52px;
  margin-bottom: 8px;
`;

const TData = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  .head {
    display: flex;
    align-items: center;
    position: relative;
    top: 13px;
    min-width: 120px;
    height: fit-content;

    p {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 15px;
      font-weight: 600;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.3px;
    }

    span {
      margin-left: 4px;
      cursor: pointer;
    }
  }

  .body {
    flex: 1;
    align-items: center;
    position: relative;
    top: 13px;
    height: fit-content;

    p {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 15px;
      font-weight: 500;
      line-height: 24px; /* 146.667% */
      letter-spacing: -0.3px;
    }
  }
`;
