import * as React from "react";

const CircleMinus28 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    fill="none"
    viewBox="0 0 28 28"
  >
    <circle cx="14" cy="14" r="13.5" fill="#fff" stroke="#666E7A"></circle>
    <path
      stroke="#666E7A"
      strokeLinecap="round"
      strokeWidth="2"
      d="M21 14H7"
    ></path>
  </svg>
);

export default CircleMinus28;
