import { useMemo, useCallback } from "react";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { deeplinkList } from "@constants/deeplink";

const ModalList = ({ editMode, setData, data }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "sortingNum",
        header: "순서",
        size: 0.1,
      },
      {
        accessorKey: "imageUrl",
        header: "등록 이미지",
        size: 0.15,
      },
      {
        accessorKey: "startAt",
        header: "시작일시",
        size: 0.25,
      },
      {
        accessorKey: "endAt",
        header: "종료일시",
        size: 0.25,
      },
      {
        accessorKey: "deepLink",
        header: "이동화면",
        size: 0.25,
      },
    ],
    []
  );

  const moveRow = useCallback((activeIndex, overIndex) => {
    setData((prevData) => arrayMove(prevData, activeIndex, overIndex));
  }, []);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const renderRow = (row, index) => {
    return (
      <SortableRow
        key={row.id}
        index={index}
        row={row}
        editMode={editMode}
        columns={columns}
      />
    );
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={({ active, over }) => {
        if (active.id !== over.id) {
          const activeIndex = data.findIndex((item) => item.id === active.id);
          const overIndex = data.findIndex((item) => item.id === over.id);
          moveRow(activeIndex, overIndex);
        }
      }}
    >
      <SortableContext items={data} strategy={verticalListSortingStrategy}>
        <Container>
          <Header>
            {columns.map((column, index) => (
              <div key={index} style={{ flex: column.size }}>
                {column.header}
              </div>
            ))}
          </Header>
          <Content>{data.map((row, index) => renderRow(row, index))}</Content>
        </Container>
      </SortableContext>
    </DndContext>
  );
};

const SortableRow = ({ row, index, editMode, columns }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: row.id });
  const navigate = useNavigate();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    zIndex: isDragging ? 1 : "auto",
    cursor: editMode ? "default" : "pointer",
  };

  const handleRow = () => {
    if (!editMode) {
      navigate(`${row.id}`);
    }
  };

  return (
    <Row ref={setNodeRef} style={style} {...attributes} onClick={handleRow}>
      {columns.map((column, colIndex) => {
        if (column.accessorKey === "sortingNum") {
          return (
            <Cell key={colIndex} style={{ flex: column.size }}>
              {editMode ? (
                <RowDragHandleCell listeners={listeners} />
              ) : (
                <span>{index + 1}</span>
              )}
            </Cell>
          );
        }

        if (column.accessorKey === "imageUrl") {
          return (
            <Cell key={colIndex} style={{ flex: column.size }}>
              <ImgBox>
                <img src={row[column.accessorKey]} alt="이미지" />
              </ImgBox>
            </Cell>
          );
        }

        if (
          column.accessorKey === "startAt" ||
          column.accessorKey === "endAt"
        ) {
          return (
            <Cell key={colIndex} style={{ flex: column.size }}>
              {moment(new Date(row[column.accessorKey])).format(
                "YYYY-MM-DD HH:mm"
              )}
            </Cell>
          );
        }

        if (column.accessorKey === "deepLink") {
          if (row[column.accessorKey] === "external-url") {
            return (
              <Cell key={colIndex} style={{ flex: column.size }}>
                <span>{row.externalUrl}</span>
              </Cell>
            );
          }

          if (row[column.accessorKey]) {
            // {id:1} 형태를 :id로 변경
            const result = row.deepLink.replace(/{id:[\w\d]+}/g, ":id");
            // deeplinkList에서 result와 일치하는 객체 찾기
            const deeplink = deeplinkList.find((item) =>
              item.contents.some((content) => content.value === result)
            );
            // deeplinkList에서 result와 일치하는 객체의 title 찾기
            const title = deeplink
              ? deeplink.contents.find((content) => content.value === result)
                  ?.title
              : "-";
            return (
              <Cell key={colIndex} style={{ flex: column.size }}>
                <span>{title}</span>
              </Cell>
            );
          }
          return (
            <Cell key={colIndex} style={{ flex: column.size }}>
              <span>선택안함</span>
            </Cell>
          );
        }
      })}
    </Row>
  );
};

const RowDragHandleCell = ({ listeners }) => {
  return (
    <div
      style={{
        cursor: "move",
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
      {...listeners}
    >
      <span>🟰</span>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray200};
    border-top: 2px solid ${({ theme }) => theme.colors.blueGray800};
    background: ${({ theme }) => theme.colors.blueGrayA100};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 12px;
  border-bottom: 1px solid #ddd;

  span {
    display: -webkit-box; /* 플렉스 기반의 레이아웃 박스를 생성 */
    -webkit-line-clamp: 2; /* 최대 3줄 표시 */
    -webkit-box-orient: vertical; /* 박스를 수직 방향으로 설정 */
    overflow: hidden; /* 넘치는 텍스트 숨김 */
    white-space: pre-wrap; /* 줄바꿈 유지 */
    word-break: break-word; /* 단어가 넘치면 적절히 나눔 */
    color: ${({ theme }) => theme.colors.blueGray700};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;

const ImgBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 33px;
    height: 42px;
    border-radius: 6px;
  }
`;

export default ModalList;
