import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Title } from "@components/styles";
import SeriesDetail from "./SeriesDetail";

import { useGetSeriesDetail } from "@hooks/admin";

export default function EditSeries() {
  const params = useParams();
  const { data } = useGetSeriesDetail(Number(params.id));

  const [seriesInfo, setSeriesInfo] = useState({
    series_id: null,
    title: "",
    desc: "",
    order: null,
    report_list: [],
  });
  const [newReportList, setNewReportList] = useState([]);

  useEffect(() => {
    if (!data) return;

    setSeriesInfo(data);
    setNewReportList(
      data.report_list.map((v) => ({
        id: v.content_id,
        label: v.title,
        report: v,
      }))
    );
  }, [data]);

  return (
    <Container>
      <Title>시리즈 정보 수정</Title>
      <Separator />
      <SeriesDetail
        seriesId={Number(params.id)}
        seriesInfo={seriesInfo}
        setSeriesInfo={setSeriesInfo}
        newReportList={newReportList}
        setNewReportList={setNewReportList}
      />
    </Container>
  );
}

const Container = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;

const Separator = styled.div`
  margin-top: 32px;
  height: 2px;
  background-color: #333e4d;
`;
