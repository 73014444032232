import { useState, useEffect } from "react";
import useCustomSearchParams from "@hooks/useCustomSearchParams";
import styled from "styled-components";

import LineTabButton from "@components/button/LineTabButton";
import { Title } from "@components/styles";
import ReportList from "./ReportList";
import { SeriesList } from "./series";
import { PopularList } from "./popular";

const tabData = [
  { title: "레포트 등록", value: "report" },
  { title: "시리즈 관리", value: "series" },
  { title: "인기 레포트 관리", value: "popular" },
];

export default function Weekly() {
  const { searchParams, setSearchParams } = useCustomSearchParams();
  const status = searchParams.tab;
  const [tabStatus, setTabStatus] = useState(status);

  const handleTabStatus = (value) => {
    setTabStatus(value);
    setSearchParams({ tab: value });
  };

  useEffect(() => {
    if (!status) {
      setTabStatus("report");
      setSearchParams({ tab: "report" });
    }
  }, [status]);

  return (
    <Container>
      <Title>레포트 등록 관리</Title>
      <LineTabButton
        tabData={tabData}
        tabStatus={tabStatus}
        setTabStatus={handleTabStatus}
      />
      {tabStatus === "report" && <ReportList />}
      {tabStatus === "series" && <SeriesList />}
      {tabStatus === "popular" && <PopularList />}
    </Container>
  );
}

const Container = styled.div``;
