import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  useEditReport,
  useGenPresignedUrl,
  useDeleteReport,
} from "@hooks/admin";
import { uploadReportThumbnailImageToS3 } from "@utils/file";
import ModalContainer from "@components/core/modal/ModalContainer";
import GradientButton from "@components/button/GradientButton";
import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";

export default function ConfirmButton({
  contentId,
  reportInfo,
  additional,
  isFavoriteActive,
}) {
  const [삭제모달상태, 삭제모달상태변경] = useState(false);
  const navigate = useNavigate();
  const { mutate: 레포트수정, isLoading } = useEditReport();
  const { mutateAsync: genPresignedUrl } = useGenPresignedUrl();
  const { mutate: 레포트삭제 } = useDeleteReport();

  const isDone = useMemo(() => {
    const allPushFields = [
      reportInfo.push_target,
      reportInfo.push_title,
      reportInfo.push_body,
      reportInfo.push_schedule,
    ];

    const areAllPushFieldsConsistent =
      allPushFields.every(Boolean) || allPushFields.every((field) => !field);

    const isFavoriteDatesValid = !isFavoriteActive
      ? reportInfo.new_report_start_date === null &&
        reportInfo.new_report_end_date === null
      : reportInfo.new_report_start_date && reportInfo.new_report_end_date;

    return (
      reportInfo.influencer &&
      reportInfo.new_reports_desc &&
      (additional === "none" ||
        (additional === "consulting_id" && reportInfo.consulting_id) ||
        (additional === "danji_list" && reportInfo.danji_list?.length > 0)) &&
      reportInfo.kakao_shared_thumbnail.fileName &&
      reportInfo.thumbnail_80.fileName &&
      reportInfo.thumbnail_120.fileName &&
      reportInfo.thumbnail_327.fileName &&
      reportInfo.filter_tags.length > 0 &&
      reportInfo.payment_type &&
      reportInfo.coin_amount !== null &&
      isFavoriteDatesValid
    );
  }, [reportInfo, additional, isFavoriteActive]);

  const 등록 = () => {
    const isoString = new Date().toISOString();

    const imageFields = [
      "kakao_shared_thumbnail",
      "thumbnail_80",
      "thumbnail_120",
      "thumbnail_327",
    ];

    const uploadedImages = {};

    const promises = imageFields.map(async (field) => {
      if (!reportInfo[field].file) {
        uploadedImages[field] = reportInfo[field].fileName;
        return;
      }

      const objectKey = `contents_id_${contentId}/${field}_${isoString}.png`;
      const res = await genPresignedUrl(objectKey);
      await uploadReportThumbnailImageToS3({
        presignedObj: res.presigned_url,
        file: reportInfo[field].file,
      });
      uploadedImages[field] = `${res.presigned_url.url.replace(
        "s3",
        "s3.ap-northeast-2"
      )}${objectKey}`;
    });

    Promise.all(promises)
      .then(() => {
        레포트수정(
          { content_id: contentId, data: { ...reportInfo, ...uploadedImages } },
          {
            onSuccess() {
              alert("수정 완료!");
              navigate(-1);
            },
            onError() {
              alert("error");
            },
          }
        );
      })
      .catch((error) => {
        console.error("이미지 업로드 중 오류 발생:", error);
      });
  };

  const 삭제 = () => {
    레포트삭제(
      { contents_id: contentId },
      {
        onSuccess() {
          alert("삭제 성공!");
          삭제모달상태변경(false);
          navigate(-1);
        },
        onError() {
          alert("실패!");
        },
      }
    );
  };

  return (
    <>
      <ButtonContainer>
        <Container
          style={{ backgroundColor: "#F04452" }}
          onClick={() => 삭제모달상태변경(true)}
          $isDone
        >
          삭제
        </Container>
        <Container onClick={등록} $isDone={isDone} disabled={!isDone}>
          등록
        </Container>
      </ButtonContainer>
      {isLoading && <AbsoluteFillLoading />}
      <ModalContainer
        isVisible={삭제모달상태}
        setIsVisible={삭제모달상태변경}
        title="등록한 정보를 삭제할까요?"
        onCloseButton
        bottomButton={
          <GradientButton
            style={{ backgroundColor: "#F04452" }}
            text={"삭제하기"}
            isDone
            onClick={삭제}
          />
        }
      >
        <Content>
          <h3>아래 사항을 확인하세요</h3>
          <ul>
            <li>삭제한 레포트는 더이상 보이지 않습니다.</li>
            <li>생성된 레포트ID는 더 이상 사용할 수 없습니다.</li>
            <li>인기레포트 및 푸시등록 정보도 모두 삭제됩니다.</li>
          </ul>
        </Content>
      </ModalContainer>
    </>
  );
}

const ButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Container = styled.button<{ $isDone?: boolean }>`
  width: 140px;
  height: 48px;
  border-radius: 8px;

  background-color: ${({ $isDone }) => ($isDone ? "#1C84FF" : "#e5e7e9")};

  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  color: ${({ $isDone }) => ($isDone ? "#fff" : "#808690")};
`;

const Content = styled.div`
  padding: 0 8px 30px;
  width: 344px;
  height: 200px;
  overflow-y: auto;

  h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #333e4d;
    margin-bottom: 8px;
  }

  li {
    display: flex;
    align-items: center;
    color: #4d5664;
    height: 32px;

    &::before {
      content: "⦁";
      margin-right: 8px;
    }

    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
  }
`;
