import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";

export default function Section({
  title,
  desc,
  bodyComponent,
  style,
  showStar = false,
}: {
  title: string;
  desc?: string;
  bodyComponent?: ReactNode;
  style?: CSSProperties;
  showStar?: boolean;
}) {
  return (
    <TRow style={style}>
      <Head $showStar={showStar}>
        <h3>{title}</h3>
        {desc && <p>{desc}</p>}
      </Head>

      <Body>{bodyComponent}</Body>
    </TRow>
  );
}

const TRow = styled.div`
  display: flex;
  padding: 16px;
`;

const Head = styled.div<{ $showStar?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 16px 0; */
  min-width: 150px;
  margin-top: 12px;

  h3 {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;

    &::after {
      color: #f04452;
      content: ${({ $showStar }) => ($showStar ? '"*"' : '" "')};
    }
  }

  p {
    margin-top: 4px;
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 13px;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    letter-spacing: -0.26px;
  }
`;

const Body = styled.div`
  flex: 1;
  /* padding: 16px 0 16px 24px; */
`;

const Star = styled.p`
  &::after {
    color: #f04452;
    content: "*";
  }
`;
