import { useState } from "react";
import ReportListTable from "./ReportListTable";
import TitleBox from "./TitleBox";

import { useGetPopularReportList } from "@hooks/admin";

export default function PopularList() {
  const [selectedFilter, setSelectedFilter] = useState({
    label: "전체 상태",
    value: null,
  });
  const { data, isLoading } = useGetPopularReportList({
    status: selectedFilter.value,
  });

  return (
    <div>
      <TitleBox
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
      <ReportListTable data={data || []} isLoading={isLoading} />
    </div>
  );
}
