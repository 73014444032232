import { BTypeText48, TextInputEnum } from "@components/input/BTypeText48";

export default function ReportTitle({ reportInfo }) {
  return (
    <BTypeText48
      placeholder=""
      textType={TextInputEnum.DEFAULT}
      value={reportInfo.title}
      disabled
      onChangeValue={() => {}}
      containerStyle={{ marginTop: 14 }}
    />
  );
}
