import { useState, useCallback } from "react";
import { debounce } from "lodash";
import styled from "styled-components";

import SeriesListItem from "./SeriesListItem";
import ModalContainer from "@components/core/modal/ModalContainer";
import GradientButton from "@components/button/GradientButton";
import { BTypeText48, TextInputEnum } from "@components/input/BTypeText48";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import { useGetSeriesList, useCreateSeries } from "@hooks/admin";
import PlusIcon from "@assets/icon/PlusIcon";

export default function SeriesList() {
  const [searchActive, setSearchActive] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const { data, isLoading } = useGetSeriesList(debouncedValue);
  const { mutate: 시리즈생성 } = useCreateSeries();
  const [시리즈추가등록모달상태, 시리즈추가등록모달상태변경] = useState(false);

  const [seriesTitle, setSeriesTitle] = useState("");
  const [seriesDesc, setSeriesDesc] = useState("");

  // debounce 처리: 최종 입력값만 업데이트
  const handleInputChange = useCallback(
    debounce((value) => {
      setDebouncedValue(value); // 일정 시간 후 최종 값만 반영
    }, 500),
    []
  );

  const onChange = (e) => {
    setInputValue(e.target.value);
    handleInputChange(e.target.value);
  };

  const 추가등록_모달열기 = () => {
    setSeriesTitle("");
    setSeriesDesc("");
    시리즈추가등록모달상태변경(true);
  };

  const 시리즈추가 = () => {
    시리즈생성(
      {
        data: {
          title: seriesTitle,
          desc: seriesDesc,
          contentsIdList: [],
        },
      },
      {
        onSuccess() {
          alert("성공");
          시리즈추가등록모달상태변경(false);
        },
        onError() {
          alert("실패");
        },
      }
    );
  };

  return (
    <Container>
      <ButtonContainer>
        <CreateButton onClick={추가등록_모달열기}>
          <PlusIcon color="#4D5664" />
          <p>새로운 시리즈 추가하기</p>
        </CreateButton>

        <SearchContainer>
          {!searchActive ? (
            <div
              onClick={() => setSearchActive(true)}
              style={{
                position: "relative",
                height: 40,
                width: 40,
                top: 0,
                cursor: "pointer",
              }}
            >
              <SearchIcon />
            </div>
          ) : (
            <div style={{ position: "relative" }}>
              <SearchInput
                placeholder="제목으로 검색하기"
                value={inputValue}
                onChange={onChange}
              />

              <SearchIcon />
              <CloseIcon
                onClick={() => {
                  setInputValue("");
                  handleInputChange("");
                }}
              />
            </div>
          )}
        </SearchContainer>
      </ButtonContainer>
      <ListContainer>
        {isLoading
          ? "로딩중..."
          : !data
          ? "데이터 없음"
          : data.map((v, i) => <SeriesListItem key={v.series_id} {...v} />)}
      </ListContainer>
      <ModalContainer
        isVisible={시리즈추가등록모달상태}
        setIsVisible={시리즈추가등록모달상태변경}
        title="시리즈 추가"
        onCloseButton
        bottomButton={
          <GradientButton
            text={"등록"}
            isDone={!!(seriesTitle && seriesDesc)}
            onClick={시리즈추가}
          />
        }
      >
        <Content>
          <BTypeText48
            inputType={InputTypeEnum.OnlyText}
            value={seriesTitle}
            placeholder="시리즈 이름을 작성하세요"
            onChangeValue={(value) => setSeriesTitle(value)}
            textType={TextInputEnum.DEFAULT}
            containerStyle={{ height: 56 }}
          />
          <BTypeTextArea
            placeholder="시리즈에 대한 간단한 설명을 작성하세요"
            value={seriesDesc}
            onChangeValue={(value) => setSeriesDesc(value)}
            textareaStyle={{ height: 200, fontSize: 16 }}
          />
        </Content>
      </ModalContainer>
    </Container>
  );
}

const Container = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #333e4d;
  padding-bottom: 8px;
`;

const CreateButton = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  p {
    margin-left: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #4d5664;
  }
`;

const SearchContainer = styled.div``;

const SearchIcon = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div
      onClick={onClick}
      style={{ position: "absolute", left: 10, top: 8, cursor: "pointer" }}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.562 6.55169C13.4946 5.47485 12.0682 4.8892 10.5568 4.8892C9.04548 4.8892 7.61912 5.4843 6.55172 6.55169C5.47487 7.61909 4.88921 9.04544 4.88921 10.5568C4.88921 12.0682 5.48431 13.4945 6.55172 14.5619C7.61912 15.6387 9.04548 16.2244 10.5568 16.2244C12.0682 16.2244 13.4946 15.6293 14.562 14.5619C15.6388 13.4945 16.2245 12.0682 16.2245 10.5568C16.2245 9.04544 15.6294 7.61909 14.562 6.55169ZM5.21038 5.21036C6.69341 3.73679 8.62041 3 10.5568 3V3.00945C12.4933 3.00945 14.4297 3.74623 15.9033 5.21981C18.6108 7.92729 18.831 12.1893 16.5639 15.1496L20.7104 19.2962C21.1009 19.6867 21.1009 20.3199 20.7104 20.7104C20.3199 21.1009 19.6867 21.1009 19.2962 20.7104L15.1482 16.5624C13.7947 17.5965 12.1791 18.1136 10.5568 18.1136C8.62041 18.1136 6.68396 17.3768 5.21038 15.9032C2.26321 12.9466 2.26321 8.16696 5.21038 5.21036Z"
          fill="#808690"
        />
      </svg>
    </div>
  );
};

function CloseIcon({ onClick }: { onClick?: () => void }) {
  return (
    <div
      onClick={onClick}
      style={{ position: "absolute", right: 10, top: 9, cursor: "pointer" }}
    >
      <svg
        width={22}
        height={22}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx={11} cy={11} r={11} fill="#B2B7BC" />
        <path d="M7 7l8 8M15 7l-8 8" stroke="#fff" strokeLinecap="round" />
      </svg>
    </div>
  );
}

const SearchInput = styled.input`
  display: flex;
  width: 374px;
  height: 40px;
  padding: 8px 10px 8px 40px;
  border-radius: 8px;
  background: #f4f6f8;
  justify-content: center;
  align-items: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.colors.blueGray800};

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
  }
  &:focus {
    outline: none;
  }
`;

const Content = styled.div`
  padding: 0 8px 30px;
  width: 344px;
  height: 300px;
  overflow-y: auto;
`;

const ListContainer = styled.ul``;
