import { useEffect, useState } from "react";
import styled from "styled-components";
import SelectDate from "../components/SelectDate";
import AddImage from "../components/AddImage";
import ConfirmButton from "../components/ConfirmButton";
import MovingSelect from "../components/MovingSelect";

export default function ModalAdd() {
  const [modalInfo, setModalInfo] = useState({
    startDate: null,
    endDate: null,
    file: undefined,
    deeplink: undefined,
    deeplinkId: "",
    externalUrl: undefined,
    fileName: "",
  });
  const [isDone, setIsDone] = useState(false);

  const updateImg = (file: File, fileName: string) => {
    setModalInfo((prev) => ({ ...prev, file, fileName }));
  };

  useEffect(() => {
    if (modalInfo.startDate && modalInfo.endDate && modalInfo.file) {
      if (modalInfo.deeplink?.includes(":id") && !modalInfo.deeplinkId) {
        setIsDone(false);
        return;
      }
      if (modalInfo.deeplink === "external-url" && !modalInfo.externalUrl) {
        setIsDone(false);
        return;
      }
      setIsDone(true);
    } else {
      setIsDone(false);
    }
  }, [modalInfo]);

  return (
    <Container>
      <Title>
        <h1>모달 추가 등록</h1>
      </Title>
      <div className="table">
        <TRow>
          <TData>
            <div className="head">
              <p>시작 일시</p>
              <Star />
            </div>
            <SelectDate
              date={modalInfo.startDate}
              setDate={(v) =>
                setModalInfo((prev) => ({ ...prev, startDate: v }))
              }
            />
          </TData>
          <TData>
            <div className="head">
              <p>종료 일시</p>
              <Star />
            </div>
            <SelectDate
              date={modalInfo.endDate}
              setDate={(v) => setModalInfo((prev) => ({ ...prev, endDate: v }))}
            />
          </TData>
        </TRow>
        <TRow>
          <TData>
            <div className="head">
              <p>이미지 등록</p>
              <Star />
            </div>
            <AddImage updateImg={updateImg} fileName={modalInfo.fileName} />
          </TData>
        </TRow>
        <TRow>
          <TData>
            <div className="head">
              <p>이동 화면</p>
            </div>
            <MovingSelect modalInfo={modalInfo} setModalInfo={setModalInfo} />
          </TData>
        </TRow>
      </div>
      <ConfirmButton isDone={isDone} modalInfo={modalInfo} />
    </Container>
  );
}

const Title = styled.div`
  padding-bottom: 32px;

  h1 {
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 32px;
    font-weight: 600;
    line-height: 42px; /* 131.25% */
    letter-spacing: -0.64px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 954px;
  .table {
    padding: 14px 0 0px;
    border-top: 2px solid ${({ theme }) => theme.colors.blueGray800};
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray100};
  }

  .row {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    gap: 12px;

    h3 {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 18px;
      font-weight: 600;
      line-height: 26px; /* 144.444% */
      letter-spacing: -0.36px;
    }

    p {
      color: ${({ theme }) => theme.colors.red1000};
      font-size: 15px;
      font-weight: 400;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.3px;
    }
  }
`;

const TRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 52px;
  margin-bottom: 32px;
`;

const TData = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;

  .head {
    display: flex;
    min-width: 140px;
    height: fit-content;

    p {
      color: ${({ theme }) => theme.colors.blueGray800};
      font-size: 15px;
      font-weight: 600;
      line-height: 22px; /* 146.667% */
      letter-spacing: -0.3px;
    }

    span {
      margin-left: 4px;
      cursor: pointer;
    }
  }

  .body {
    flex: 1;
  }
`;

const Star = styled.p`
  &::after {
    margin-left: 2px;
    color: #f04452;
    content: "*";
  }
`;
