import styled from "styled-components";
import CheckedCircle24 from "../icon/checked/CheckedCircle24";
import { CheckedCircleStatusEnum } from "../icon/checked/CheckedCircle.interfaces";
import { CSSProperties } from "react";

interface Props {
  active?: boolean;
  text: string;
  activeColor?: string;
  onClick?: () => void;
  containerStyle?: CSSProperties;
}

export default function CircleCheckRow({
  active,
  text,
  activeColor,
  onClick,
  containerStyle,
}: Props) {
  return (
    <Row onClick={onClick} style={containerStyle}>
      <CheckedCircle24
        status={
          active
            ? CheckedCircleStatusEnum.Activate
            : CheckedCircleStatusEnum.Default
        }
        activeColor={activeColor}
      />
      <span>{text}</span>
    </Row>
  );
}

const Row = styled.button`
  display: flex;
  padding: 12px 8px;
  width: 100%;

  span {
    margin-left: 12px;
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.32px;
    font-feature-settings: "liga" off, "clig" off;
  }
`;
