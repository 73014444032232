import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Main from "./pages/main/Main";
import PrivateRouter from "@components/layout/PrivateRouter";

// import UserRouter from "router/UserRouter";
// import ContentRouter from "router/ContentRouter";

import {
  UserRouter,
  ContentRouter,
  ConsultationRouter,
  ImjangRouter,
  ManageRouter,
  NoticeRouter,
  ReportRouter,
  ConsultingRouter,
  VisitRouter,
  NotificationRouter,
  PublicRouter,
  DevRouter,
} from "./router/index";
import NavLayout from "@components/layout/NavLayout";
import GlobalProvider from "@components/layout/GlobalProvider";
import AddInfoPage from "@pages/addInfo/AddInfoPage";
import DevLayout from "@components/layout/DevLayout";
import {
  Preview,
  PreviewReport,
  SearchSeriesReport,
} from "@pages/admin/report/weekly";
import { SearchDanji } from "@components/searchDanji";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<GlobalProvider />}>
          <Route element={<PrivateRouter authentication={false} />}>
            <Route path="/*" element={<PublicRouter />} />
          </Route>
          <Route element={<PrivateRouter authentication={true} />}>
            <Route path="/add-info/*" element={<AddInfoPage />} />
            <Route path="/preview/weekly/draft/:id" element={<Preview />} />
            <Route path="/preview/weekly/:id" element={<PreviewReport />} />
            <Route path="/search/danji" element={<SearchDanji />} />
            <Route
              path="/search/series/:id/report"
              element={<SearchSeriesReport />}
            />
            <Route element={<NavLayout />}>
              <Route path="/" element={<Main />} />
              {/* 회원 및 멤버 관리 */}
              <Route
                path="/users/*"
                element={<UserRouter pageValue={"A01"} />}
              />
              {/* 기고문 관리 */}
              <Route
                path="/content/*"
                element={<ContentRouter pageValue={"A02"} />}
              />
              {/* 상담소 관리 */}
              <Route
                path="/consultation/*"
                element={<ConsultationRouter pageValue={"A03"} />}
              />
              {/* 임장 비서 관리 */}
              <Route
                path="/imjang/*"
                element={<ImjangRouter pageValue={"A04"} />}
              />
              {/* 운영 관리 */}
              <Route
                path="/manage/*"
                element={<ManageRouter pageValue={"A05"} />}
              />
              <Route
                path="/notice/*"
                element={<NoticeRouter pageValue={"A06"} />}
              />
              <Route
                path="/report/*"
                element={<ReportRouter pageValue={"B01"} />}
              />
              <Route
                path="/consulting/*"
                element={<ConsultingRouter pageValue={"B02"} />}
              />
              <Route
                path="/visit/*"
                element={<VisitRouter pageValue={"B03"} />}
              />
              <Route
                path="/notification/*"
                element={<NotificationRouter pageValue={"B04"} />}
              />
            </Route>
            <Route element={<DevLayout />}>
              <Route path="/dev/*" element={<DevRouter />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
