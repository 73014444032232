import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import styled from "styled-components";
import {
  useGetReportAvailableId,
  useGetReportList,
  useCeateDraftReport,
  useGetDraftReportDetail,
} from "@hooks/admin";

import ModalContainer from "@components/core/modal/ModalContainer";
import GradientButton from "@components/button/GradientButton";
import { BTypeText48, TextInputEnum } from "@components/input/BTypeText48";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import ReportListTable from "./ReportListTable";

export default function ReportList() {
  const [searchActive, setSearchActive] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const { data, isLoading } = useGetReportList(debouncedValue);
  const [레포트추가등록모달상태, 레포트추가등록모달상태변경] = useState(false);
  const [pageUrl, setPageUrl] = useState("");
  const [title, setTitle] = useState("");
  const { mutate: 레포트id조회, data: contentsIdData } =
    useGetReportAvailableId();
  const { mutate: 레포트초안생성 } = useCeateDraftReport();
  const { mutate: 레포트초안상세조회 } = useGetDraftReportDetail();
  const [buttonLoading, setButtonLoading] = useState(false);

  // debounce 처리: 최종 입력값만 업데이트
  const handleInputChange = useCallback(
    debounce((value) => {
      setDebouncedValue(value); // 일정 시간 후 최종 값만 반영
    }, 500),
    []
  );

  const onChange = (e) => {
    setInputValue(e.target.value);
    handleInputChange(e.target.value);
  };

  const 레포트_추가등록_모달열기 = () => {
    setButtonLoading(false);
    setPageUrl("");
    레포트추가등록모달상태변경(true);
    레포트id조회(undefined);
  };

  const html생성 = () => {
    setButtonLoading(true);

    const currentContentsId = contentsIdData.contents_id;
    레포트초안생성(
      {
        content_id: currentContentsId,
        body: {
          page_url: pageUrl,
          title,
        },
      },
      {
        onSuccess() {
          let attempt = 0;
          const intervalId = setInterval(() => {
            attempt++;
            레포트초안상세조회(
              { content_id: currentContentsId },
              {
                onSuccess: (detail) => {
                  if (detail.html) {
                    // 특정 값 체크
                    clearInterval(intervalId); // 성공 시 Interval 제거
                    alert(
                      "생성 완료\n팝업이 차단된 경우 생성된 html을 확인할 수 없으며, 팝업 허용 후 추가 레포트를 다시 등록해주세요"
                    );
                    setButtonLoading(false);

                    const left = window.screenX + 100; // 현재 창의 X 좌표 기준
                    const top = window.screenY + 100; // 현재 창의 Y 좌표 기준

                    window.open(
                      `/preview/weekly/draft/${detail.contents_id}`,
                      "popupWindow", // 창 이름
                      `width=${900},height=${722},left=${left},top=${top},scrollbars=no` // 창 크기 및 옵션 설정
                    );
                  }
                },
                onError: () => {
                  alert("레포트 초안 상세 조회 실패");
                  clearInterval(intervalId);
                  setButtonLoading(false);
                },
              }
            );

            if (attempt >= 30) {
              clearInterval(intervalId); // Interval 제거
              alert("최대 대기 시간 초과. 특정 값 생성 안 됨.");
              setButtonLoading(false);
            }
          }, 2000); // 2초마다 실행
        },
        onError() {
          alert("임시 레포트 생성에 실패했습니다.\n개발팀에 문의하세요.");
          setButtonLoading(false);
        },
      }
    );
  };

  const navigate = useNavigate();

  useEffect(() => {
    // 부모 페이지에서 메시지 수신
    const handleMessage = (event) => {
      if (event.data.type === "navigate") {
        navigate(event.data.path);
      }
    };

    // 메시지 리스너 추가
    window.addEventListener("message", handleMessage);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [navigate]);

  return (
    <Container>
      <ButtonContainer>
        <CreateButton onClick={레포트_추가등록_모달열기}>
          <p>레포트 추가 등록</p>
        </CreateButton>

        <SearchContainer>
          {!searchActive ? (
            <div
              onClick={() => setSearchActive(true)}
              style={{
                position: "relative",
                height: 40,
                width: 40,
                top: 0,
                cursor: "pointer",
              }}
            >
              <SearchIcon />
            </div>
          ) : (
            <div style={{ position: "relative" }}>
              <SearchInput
                placeholder="제목으로 검색하기"
                value={inputValue}
                onChange={onChange}
              />

              <SearchIcon />
              <CloseIcon
                onClick={() => {
                  setInputValue("");
                  handleInputChange("");
                }}
              />
            </div>
          )}
        </SearchContainer>
      </ButtonContainer>
      <ReportListTable data={data || []} isLoading={isLoading} />
      <ModalContainer
        isVisible={레포트추가등록모달상태}
        setIsVisible={레포트추가등록모달상태변경}
        title="레포트 추가 등록"
        onCloseButton
        canBackdropClose={false}
        bottomButton={
          <GradientButton
            text={buttonLoading ? "로딩 중..." : "html 생성"}
            isDone={!!pageUrl}
            onClick={html생성}
            disabled={buttonLoading}
            style={{ backgroundColor: buttonLoading ? "#B2D6FF" : "#1C84FF" }}
          />
        }
      >
        <Content>
          <BTypeText48
            inputType={InputTypeEnum.OnlyText}
            value={`레포트 ID: ${contentsIdData?.contents_id || ""}`}
            placeholder=""
            onChangeValue={() => {}}
            textType={TextInputEnum.DEFAULT}
            disabled
            containerStyle={{ height: 56 }}
          />
          <BTypeText48
            inputType={InputTypeEnum.OnlyText}
            value={title}
            placeholder="제목을 입력하세요"
            onChangeValue={(value) => setTitle(value)}
            textType={TextInputEnum.DEFAULT}
            containerStyle={{ height: 56 }}
            disabled={buttonLoading}
          />
          <BTypeText48
            inputType={InputTypeEnum.OnlyText}
            value={pageUrl}
            placeholder="등록할 레포트 링크를 입력해주세요"
            onChangeValue={(value) => setPageUrl(value)}
            textType={TextInputEnum.DEFAULT}
            containerStyle={{ height: 56 }}
            disabled={buttonLoading}
          />
        </Content>
      </ModalContainer>
    </Container>
  );
}

const Container = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CreateButton = styled.div`
  padding: 9px 16px;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;

  p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: white;
  }
`;

const SearchContainer = styled.div``;

const Content = styled.div`
  padding: 0 8px 30px;
  width: 344px;
  height: 200px;
  overflow-y: auto;
`;

const SearchInput = styled.input`
  display: flex;
  width: 374px;
  height: 40px;
  padding: 8px 10px 8px 40px;
  border-radius: 8px;
  background: #f4f6f8;
  justify-content: center;
  align-items: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: -0.3px;
  color: ${({ theme }) => theme.colors.blueGray800};

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
  }
  &:focus {
    outline: none;
  }
`;

const SearchIcon = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div
      onClick={onClick}
      style={{ position: "absolute", left: 10, top: 8, cursor: "pointer" }}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.562 6.55169C13.4946 5.47485 12.0682 4.8892 10.5568 4.8892C9.04548 4.8892 7.61912 5.4843 6.55172 6.55169C5.47487 7.61909 4.88921 9.04544 4.88921 10.5568C4.88921 12.0682 5.48431 13.4945 6.55172 14.5619C7.61912 15.6387 9.04548 16.2244 10.5568 16.2244C12.0682 16.2244 13.4946 15.6293 14.562 14.5619C15.6388 13.4945 16.2245 12.0682 16.2245 10.5568C16.2245 9.04544 15.6294 7.61909 14.562 6.55169ZM5.21038 5.21036C6.69341 3.73679 8.62041 3 10.5568 3V3.00945C12.4933 3.00945 14.4297 3.74623 15.9033 5.21981C18.6108 7.92729 18.831 12.1893 16.5639 15.1496L20.7104 19.2962C21.1009 19.6867 21.1009 20.3199 20.7104 20.7104C20.3199 21.1009 19.6867 21.1009 19.2962 20.7104L15.1482 16.5624C13.7947 17.5965 12.1791 18.1136 10.5568 18.1136C8.62041 18.1136 6.68396 17.3768 5.21038 15.9032C2.26321 12.9466 2.26321 8.16696 5.21038 5.21036Z"
          fill="#808690"
        />
      </svg>
    </div>
  );
};

function CloseIcon({ onClick }: { onClick?: () => void }) {
  return (
    <div
      onClick={onClick}
      style={{ position: "absolute", right: 10, top: 9, cursor: "pointer" }}
    >
      <svg
        width={22}
        height={22}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx={11} cy={11} r={11} fill="#B2B7BC" />
        <path d="M7 7l8 8M15 7l-8 8" stroke="#fff" strokeLinecap="round" />
      </svg>
    </div>
  );
}
