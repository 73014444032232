import { Instance } from "@api/index";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ModalListRes, ModalReq } from "./modal.interfaces";

export const useGetPresignedUrl = () => {
  return useMutation({
    mutationFn: async ({ type }: { type: string }) => {
      const response = await Instance({
        url: `/admin/modal/pre-signed-url?file_type=${type}`,
        method: "GET",
      });
      return response.data;
    },
  });
};

export const useGetModalList = () =>
  useQuery<ModalListRes, AxiosError>({
    queryKey: ["modal", "list"],
    queryFn: async () => {
      const response = await Instance({
        url: `/admin/modal/list`,
        method: "GET",
      });
      return response.data;
    },
  });

export const useGetModalInfo = (id: string) =>
  useQuery({
    queryKey: ["modal", id],
    queryFn: async () => {
      const response = await Instance({
        url: `/admin/modal/modal/${id}`,
        method: "GET",
      });
      return response.data;
    },
  });

export const useEditModal = () => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, ModalReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/admin/modal/update/${data.id}`,
        method: "PUT",
        data,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["modal", "list"],
      });
    },
  });
};

export const useDeleteModal = () => {
  const queryClient = useQueryClient();
  return useMutation<
    {},
    AxiosError,
    {
      id: string;
    }
  >({
    mutationFn: async ({ id }) => {
      const response = await Instance({
        url: `/admin/modal/delete/${id}`,
        method: "DELETE",
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["modal", "list"],
      });
    },
  });
};

export const usePostModal = () => {
  const queryClient = useQueryClient();
  return useMutation<{}, AxiosError, ModalReq>({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/admin/modal/upload`,
        method: "POST",
        data,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["modal", "list"],
      });
    },
  });
};

export const useEditModalIndex = () => {
  return useMutation<
    {},
    AxiosError,
    {
      order: string[];
    }
  >({
    mutationFn: async (data) => {
      const response = await Instance({
        url: `/admin/modal/order`,
        method: "POST",
        data,
      });
      return response.data;
    },
  });
};
