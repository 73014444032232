import { Title } from "@components/styles";
import styled from "styled-components";
import QnATable from "./QnATable";
import { useGetInquiry } from "@api/management/qna";
import DropDown, {
  dropDownTypeEnum,
} from "@components/dropdown/DropDownButton";
import useCustomSearchParams from "@hooks/useCustomSearchParams";

const dropDownList = [
  { title: "전체 상태", value: null },
  { title: "답변 완료", value: "answered" },
  { title: "답변 대기", value: "unanswered" },
];

export default function QnAListPage() {
  const 페이지당_아이템개수 = 10;
  const { searchParams, setSearchParams } = useCustomSearchParams();
  const { data, isLoading } = useGetInquiry({
    offset: (Number(searchParams.page || 1) - 1) * 페이지당_아이템개수,
    limit: 10,
    state: searchParams.state || "",
  });

  const onChangeValue = (v) => setSearchParams({ state: v }, false);

  return (
    <Container>
      <Title style={{ marginBottom: 32 }}>1:1 문의 답변 관리</Title>
      <DropDown
        optionList={dropDownList}
        selectedValue={searchParams.state || null}
        onChangeValue={onChangeValue}
        type={dropDownTypeEnum.B}
        style={{ marginBottom: 16 }}
      />
      <QnATable
        data={data?.results || []}
        total={data?.total_count || 0}
        isLoading={isLoading}
      />
    </Container>
  );
}

const Container = styled.div`
  padding: 0 243px 60px;
`;
