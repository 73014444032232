import styled from "styled-components";
import { useGetScaleCodeList } from "@hooks/imjang";
import { Arrows32 } from "@components/core/icon/arrow";
import {
  ArrowDirectionEnum,
  ArrowColorEnum,
} from "@components/core/icon/arrow/Arrow.interfaces";

export default function ScaleList({
  danjiCode,
  setDanjiCode,
  selectedDanji,
  selectedPyeong,
  setSelectedPyeong,
}) {
  const { data: scaleData } = useGetScaleCodeList({ danjiCode });

  const 뒤로가기 = () => {
    setDanjiCode(null);
    setSelectedPyeong(null);
  };

  const 평형_선택 = (scaleInfo) => {
    const { scaleCode } = scaleInfo;

    if (scaleCode === selectedPyeong?.scaleCode) {
      setSelectedPyeong(null);
    } else {
      setSelectedPyeong(scaleInfo);
    }
  };

  const 등록 = () => {
    localStorage.setItem(
      "selected_danji",
      JSON.stringify({
        danjiCode,
        scaleCode: selectedPyeong.scaleCode,
        danjiInfo: { ...selectedDanji, 대표단지명: selectedDanji.단지명 },
        pyeongInfo: { ...selectedPyeong, 평타입: selectedPyeong.pyeongType },
      })
    );
    window.close();
  };

  return (
    <Container>
      <TitleBox>
        <div
          onClick={뒤로가기}
          style={{ cursor: "pointer", position: "relative", top: 2, left: -2 }}
        >
          <Arrows32
            direction={ArrowDirectionEnum.LEFT}
            color={ArrowColorEnum.B50}
          />
        </div>
        <Title>등록할 평형을 선택하세요</Title>
      </TitleBox>

      <ChipContainer>
        {scaleData?.scaleInfoList.map((el) => {
          const active = selectedPyeong?.scaleCode === el.scaleCode;

          return (
            <SelectChip
              key={el.scaleCode}
              active={active}
              onClick={() => 평형_선택(el)}
            >
              <p>{el.pyeongType}평</p>
            </SelectChip>
          );
        })}
      </ChipContainer>

      <ButtonContainer>
        <BottomButton onClick={등록} disabled={!selectedPyeong}>
          등록
        </BottomButton>
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 24px 24px 100px;
`;

const TitleBox = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.02em;
`;

const ChipContainer = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3개의 컬럼 */
  gap: 10px;
`;

const SelectChip = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, active }) =>
      active ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;

  p {
    color: ${({ theme, active }) =>
      active ? theme.colors.primaryBlue100 : theme.colors.blueGray600};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.32px;
  }
`;

const ButtonContainer = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;

  padding: 24px 20px;
`;

const BottomButton = styled.button`
  width: 100%;
  border-radius: 8px;

  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1c84ff;

  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  color: white;
`;
