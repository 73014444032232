import { useEffect, useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getCoreRowModel } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import cn from "classnames";
import Pagination from "@components/core/paging/Pagination";
import "@components/table.css";
import { convertToKoreanTime } from "@hooks/calc/converToKoreanDate";
import Empty from "@components/common/Empty";
import { useMenu } from "@features/useMenu";
import TableSkeleton from "@components/skeleton/TableSkeleton";

export default function ReportListTable({ data, isLoading }) {
  const navigate = useNavigate();
  const { globalThemeMode } = useMenu();

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("content_id", {
        cell: ({ getValue }) => getValue(),
        header: "ID",
        maxSize: 5,
      }),
      columnHelper.accessor("title", {
        cell: ({ row }) => {
          return <Title>{row.original["title"]}</Title>;
        },
        header: "제목",
        maxSize: 30,
      }),
      columnHelper.accessor("influencer", {
        cell: ({ getValue }) => {
          return getValue();
        },
        header: "작성자",
        maxSize: 10,
      }),
      columnHelper.accessor("push_schedule", {
        cell: ({ row }) => {
          return row.original["is_push_scheduled"] ? (
            convertToKoreanTime(row.original["push_schedule"])
          ) : row.original["push_schedule"] &&
            new Date() > new Date(row.original["push_schedule"]) ? (
            <span>
              {convertToKoreanTime(row.original["push_schedule"])} (만료)
            </span>
          ) : (
            <span style={{ color: "#F04452" }}>등록 정보 없음</span>
          );
        },
        header: "푸시 발송일",
        maxSize: 20,
      }),
      columnHelper.accessor("push_target", {
        cell: ({ row }) => {
          return row.original["push_target"] === "all"
            ? "전체"
            : row.original["push_target"] === "weekly"
            ? "그룹"
            : "-";
        },
        header: "푸시 대상",
        maxSize: 5,
      }),
      columnHelper.accessor("content_id", {
        id: "edit_report",
        cell: ({ getValue }) => {
          return (
            <Title
              onClick={() => {
                navigate(`/content/weekly/edit/${getValue()}`);
              }}
              style={{ cursor: "pointer" }}
            >
              레포트 정보 수정
            </Title>
          );
        },
        header: " ",
        maxSize: 10,
      }),
      columnHelper.accessor("content_id", {
        id: "edit_push",
        cell: ({ getValue }) => {
          return (
            <Title
              onClick={() => {
                navigate(`/content/weekly/edit/${getValue()}/push`);
              }}
              style={{ cursor: "pointer" }}
            >
              푸시 정보 수정
            </Title>
          );
        },
        header: " ",
        maxSize: 10,
      }),
    ];
  }, [data, isLoading]);

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  return (
    <Container>
      <table className="contentsTable">
        <thead
          className={cn("contentsThead", {
            darkContentsThead: globalThemeMode === "dark",
          })}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={cn("contentsHeader", {
                    darkContentsHeader: globalThemeMode === "dark",
                  })}
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={cn("contentsData", {
                    darkContentsData: globalThemeMode === "dark",
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Empty data={data} title="컨텐츠가 없어요" />
      )}
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div`
  margin-top: 16px;
`;

const Title = styled.h2`
  margin-left: 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
`;
