import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { Title } from "@components/styles";
import { useGetReportDetail, useReScheduleReport } from "@hooks/admin";
import ReportTitle from "./ReportTitle";
import PushSchedule from "./PushSchedule";

export default function EditPush() {
  const params = useParams();
  const { mutate: 레포트상세조회 } = useGetReportDetail();
  const { mutate: 푸시예약수정 } = useReScheduleReport();

  const [reportInfo, setReportInfo] = useState({
    page_url: "",
    title: "",
    influencer: null,
    new_reports_desc: null,
    consulting_id: null,
    danji_list: [],
    release_date: null,
    kakao_shared_thumbnail: { fileName: "", file: null, preview: null },
    thumbnail_80: { fileName: "", file: null, preview: null },
    thumbnail_120: { fileName: "", file: null, preview: null },
    thumbnail_327: { fileName: "", file: null, preview: null },
    filter_tags: [],
    payment_type: null,
    coin_amount: null,
    push_title: null,
    push_body: null,
    push_schedule: null,
    push_target: null,
    new_report_start_date: null,
    new_report_end_date: null,
  });

  useEffect(() => {
    if (params.id) {
      레포트상세조회(
        { content_id: Number(params.id) },
        {
          onSuccess(res) {
            setReportInfo(res);
          },
          onError() {
            alert("데이터 조회에 실패했습니다.");
          },
        }
      );
    }
  }, [params]);

  return (
    <Container>
      <Title>푸시 정보 수정</Title>
      <ReportTitle reportInfo={reportInfo} />
      <PushSchedule
        reportInfo={reportInfo}
        setReportInfo={setReportInfo}
        contentId={Number(params.id)}
      />
    </Container>
  );
}

const Container = styled.div`
  max-width: 960px;
  margin: 0 auto;
`;
