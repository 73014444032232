import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useGetSeriesAvailableReports } from "@hooks/admin";

export default function SearchSeriesReport() {
  const params = useParams();
  const { data } = useGetSeriesAvailableReports(Number(params.id));

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    content_id: null,
    title: "",
  });

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (report) => {
    setSelectedOption(report);
    setIsOpen(false);
  };

  const 등록 = () => {
    localStorage.setItem(
      "series_new_report",
      JSON.stringify({
        id: selectedOption.content_id,
        label: selectedOption.title,
        report: selectedOption,
      })
    );
    window.close();
  };

  return (
    <Container>
      <Title>시리즈에 등록할 컨텐츠를 선택하세요</Title>
      <SelectContainer>
        <Label onClick={toggleDropdown}>
          {selectedOption.title || "컨텐츠를 선택하세요"}
        </Label>

        <DropdownContainer>
          {isOpen && (
            <DropdownList>
              {data?.map((v) => (
                <li key={v.content_id} onClick={() => handleOptionClick(v)}>
                  {v.title}
                </li>
              ))}
            </DropdownList>
          )}
        </DropdownContainer>

        <Button onClick={등록}>등록</Button>
      </SelectContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 24px 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #333e4d;
`;

const SelectContainer = styled.div`
  width: 100%;
  margin-top: 32px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  padding: 8px 12px;
  border: 1px solid #e5e7e9;
  border-radius: 8px;

  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #4d5664;
  cursor: pointer;
`;

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 300px;
  height: 300px;
`;

const DropdownList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 100%;
  margin: 0;
  padding: 12px 8px;
  box-shadow: 0px 0px 20px 0px #00000014;
  border-radius: 16px;
  background-color: #ffffff;

  max-height: 216px;
  overflow-y: auto;
  z-index: 10;

  & li {
    padding: 4px 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #333e4d;
    cursor: pointer;

    &:hover {
      background-color: #f4f6f8;
      border-radius: 8px;
    }
  }
`;

const Button = styled.button`
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;

  padding: 12px;
  background-color: #1c84ff;
  border-radius: 8px;
  cursor: pointer;

  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  color: white;
`;
