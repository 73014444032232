import { useState } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { BTypeText48, TextInputEnum } from "@components/input/BTypeText48";
import Section from "../Section";

import { useReScheduleReport, useEditReport } from "@hooks/admin";

import { Arrows22 } from "@components/core/icon/arrow";
import {
  ArrowDirectionEnum,
  ArrowColorEnum,
} from "@components/core/icon/arrow/Arrow.interfaces";
import CircleCheckRow from "@components/core/list/CircleCheckRow";
import InfoImage from "./InfoImage.png";
import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";

const PUSH_TARGET_LIST = [
  { label: "전체 인원 발송", value: "all" },
  { label: "푸시 동의 그룹 발송", value: "weekly" },
];

export default function PushSchedule({ reportInfo, setReportInfo, contentId }) {
  const navigate = useNavigate();
  const [isShown, setIsShown] = useState(true);
  const { mutate: 레포트수정, isLoading: editLoading } = useEditReport();
  const { mutate: 푸시예약수정, isLoading: pushLoading } =
    useReScheduleReport();

  const handleTarget = (v) => {
    if (reportInfo.push_target === v) {
      setReportInfo((prev) => ({ ...prev, push_target: null }));
    } else {
      setReportInfo((prev) => ({ ...prev, push_target: v }));
    }
  };

  const 푸시재등록 = () => {
    레포트수정(
      { content_id: contentId, data: reportInfo },
      {
        onSuccess() {
          푸시예약수정(
            { content_id: contentId },
            {
              onSuccess() {
                alert("정상적으로 등록이 되었습니다");
                navigate(-1);
              },
              onError() {
                alert("푸시 스케쥴링에 실패했습니다.\n개발팀에 문의해주세요.");
              },
            }
          );
        },
        onError() {
          alert("레포트 정보 수정에 실패했습니다.\n개발팀에 문의해주세요.");
        },
      }
    );
  };

  return (
    <Container>
      <TitleBox onClick={() => setIsShown(!isShown)}>
        <div style={{ display: "flex" }}>
          <h3>푸시 설정</h3>
        </div>
        <Arrows22
          direction={isShown ? ArrowDirectionEnum.UP : ArrowDirectionEnum.DOWN}
          color={ArrowColorEnum.B50}
        />
      </TitleBox>
      {isShown && (
        <ContentContainer>
          <Section
            title="푸시 대상"
            bodyComponent={
              <ItemListContainer>
                {PUSH_TARGET_LIST.map((v) => {
                  const active = reportInfo.push_target === v.value;
                  return (
                    <CircleCheckRow
                      key={v.value}
                      text={v.label}
                      active={active}
                      onClick={() => handleTarget(v.value)}
                      containerStyle={{ width: "auto", marginRight: "8px" }}
                    />
                  );
                })}
              </ItemListContainer>
            }
          />
          <Section
            title="푸시 제목"
            bodyComponent={
              <BTypeText48
                placeholder="제목을 입력해 주세요"
                textType={TextInputEnum.DEFAULT}
                value={reportInfo.push_title}
                onChangeValue={(value) =>
                  setReportInfo((prev) => ({ ...prev, push_title: value }))
                }
                containerStyle={{ height: "auto" }}
              />
            }
          />
          <Section
            title="푸시 바디"
            bodyComponent={
              <BTypeText48
                placeholder="본문을 입력해 주세요"
                textType={TextInputEnum.DEFAULT}
                value={reportInfo.push_body}
                onChangeValue={(value) =>
                  setReportInfo((prev) => ({ ...prev, push_body: value }))
                }
                containerStyle={{ height: "auto" }}
              />
            }
          />
          <Section
            title="발송 일시"
            bodyComponent={
              <PickerContainer>
                <DatePicker
                  selected={
                    reportInfo.push_schedule
                      ? new Date(reportInfo.push_schedule)
                      : null
                  }
                  onChange={(v: Date) => {
                    setReportInfo((prev) => ({
                      ...prev,
                      push_schedule: new Date(v).toISOString(),
                    }));
                  }}
                  dateFormat="yyyy.MM.dd"
                  minDate={new Date()}
                  locale={ko}
                  timeFormat="HH:mm"
                  showTimeInput
                  shouldCloseOnSelect
                  onCalendarClose={() => {}}
                  customInput={
                    <DateInputContainer>
                      {reportInfo.push_schedule ? (
                        <div className="value">
                          {moment(reportInfo.push_schedule).format(
                            "YYYY-MM-DD HH:mm"
                          )}
                        </div>
                      ) : (
                        <div className="placeholder">날짜 선택</div>
                      )}
                    </DateInputContainer>
                  }
                />
                <DateMessage>
                  푸시가 발송되는 일시를 설정할 수 있습니다.
                </DateMessage>
              </PickerContainer>
            }
          />

          <Section
            style={{ borderTop: "1px solid #e5e7e9", margin: "0 16px" }}
            title=" "
            bodyComponent={
              <InfoContainer>
                <InfoTitle>푸시 정보를 변경하는 경우 참고하세요!</InfoTitle>
                <InfoImageBox>
                  <img src={InfoImage} alt="info" />
                </InfoImageBox>
                <InfoContentContainer>
                  <LeftContentBox>
                    아래와 같은 상황인 경우 푸시를 다시 등록해야합니다.
                    <br />
                    ⦁ 등록하지 않은 푸시를 추가하는 경우
                    <br />
                    ⦁ 푸시등록 후 에러가 발생하여 다시 등록하는 경우
                    <br />⦁ 등록한 푸시 정보를 변경하는 경우
                  </LeftContentBox>
                  <RightContentBox>
                    푸시 정보를 변경 후 하단 ‘푸시 재등록 하기’ 버튼을 클릭해야
                    푸시 정보가 수정되니 참고해 주세요.
                  </RightContentBox>
                </InfoContentContainer>
                <ReregisterButton onClick={푸시재등록}>
                  {editLoading || pushLoading ? "로딩" : "푸시 재등록 하기"}
                </ReregisterButton>
              </InfoContainer>
            }
          />
        </ContentContainer>
      )}
      {(editLoading || pushLoading) && <AbsoluteFillLoading />}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 10px;
  border: 1px solid #e5e7e9;
  border-radius: 16px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  height: 64px;
  padding: 19px 16px;

  h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #333e4d;
  }

  &:hover {
    cursor: pointer;
  }
`;

const ContentContainer = styled.div`
  border-top: 1px solid #e5e7e9;
  padding-bottom: 8px;
`;

const ItemListContainer = styled.ul`
  display: flex;
  align-items: center;
`;

const PickerContainer = styled.div`
  padding-bottom: 20px;

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const DateInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  .placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }

  .value {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;

const DateMessage = styled.p`
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #808690;
`;

const InfoContainer = styled.div`
  position: relative;
`;

const InfoImageBox = styled.div`
  position: absolute;
  left: -48px;
  top: -9px;

  img {
    width: 36px;
    height: 36px;
  }
`;

const InfoTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.blueGray800};
`;

const InfoContentContainer = styled.div`
  margin-top: 12px;
  display: flex;
`;

const LeftContentBox = styled.div`
  flex: 1;

  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.blueGray700};

  white-space: pre-wrap;
`;

const RightContentBox = styled.div`
  flex: 1;

  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.blueGray700};
`;

const ReregisterButton = styled.button`
  width: 100%;
  margin-top: 32px;
  padding: 7px 16px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colors.blue1000}`};

  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.blue1000};
`;
