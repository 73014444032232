import { useEffect, useState } from "react";
import styled from "styled-components";
import Danji from "./Danji";
import ScaleList from "./ScaleList";

export default function SearchDanji() {
  const [pushInfo, setPushInfo] = useState({ keyword: "", type: "아파트" });
  const [data, setData] = useState([]);
  const [selectedDanji, setSelectedDanji] = useState();
  const [selectedPyeong, setSelectedPyeong] = useState();
  const [danjiCode, setDanjiCode] = useState(null);

  const fetching = async ({ keyword, type }) => {
    try {
      const response = await fetch(
        `https://m.boodong.kr/search/esSearch/api?keyword=${keyword}&category=${type}&limit=100`
      );
      const data = await response.json();

      setData(data.elastic.hits.hits);
      return data;
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    fetching({ keyword: pushInfo.keyword, type: pushInfo.type });
  }, [pushInfo]);

  return (
    <Container>
      {danjiCode ? (
        <ScaleList
          danjiCode={danjiCode}
          setDanjiCode={setDanjiCode}
          selectedDanji={selectedDanji}
          selectedPyeong={selectedPyeong}
          setSelectedPyeong={setSelectedPyeong}
        />
      ) : (
        <Danji
          pushInfo={pushInfo}
          setPushInfo={setPushInfo}
          data={data}
          setDanjiCode={setDanjiCode}
          setSelectedDanji={setSelectedDanji}
        />
      )}
    </Container>
  );
}

const Container = styled.div``;
