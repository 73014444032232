import { Arrows22 } from "@components/core/icon/arrow";
import { ArrowDirectionEnum } from "@components/core/icon/arrow/Arrow.interfaces";
import styled from "styled-components";
import Empty from "@components/common/Empty";

export default function Danji({
  data,
  pushInfo,
  setPushInfo,
  setDanjiCode,
  setSelectedDanji,
}) {
  return (
    <Container>
      <Title>추가할 단지를 선택하세요</Title>
      <Row>
        <InputContainer>
          <select
            onChange={(e) =>
              setPushInfo((prev) => ({
                ...prev,
                type: e.target.value,
              }))
            }
            style={{
              color: pushInfo.type ? "#333e4d" : "#808690",
            }}
            value={pushInfo.type || "default"}
            required
          >
            <option value={"default"} disabled>
              건물 유형을 선택해주세요.
            </option>
            <option value={"아파트"}>아파트</option>
            <option value={"연립다세대"}>빌라</option>
          </select>
          <div style={{ position: "absolute", right: 16, top: 10 }}>
            <Arrows22 direction={ArrowDirectionEnum.DOWN} />
          </div>
        </InputContainer>
        <div style={{ position: "relative", flex: 2.5 }}>
          <SearchIcon />
          <Input
            placeholder="검색하기"
            style={{ width: "100%" }}
            value={pushInfo.keyword}
            onChange={(e) =>
              setPushInfo((prev) => ({ ...prev, keyword: e.target.value }))
            }
          />
        </div>
      </Row>
      <ResultContainer>
        <ul>
          {data.map((v) => (
            <li onClick={() => {
                setDanjiCode(v._source.단지코드);
                setSelectedDanji(v._source);
              }
            }>
              <HightLight
                dangerouslySetInnerHTML={{
                  __html: v["highlight"].단지명_ngram || v["_source"].단지명,
                }}
              />
              <p className="address">{v._source.도로명주소}</p>
            </li>
          ))}
        </ul>
        <Empty data={data} title="컨텐츠가 없어요" />
      </ResultContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 24px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.02em;
`;

const Row = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 6px;
`;

const InputContainer = styled.div`
  flex: 1;
  position: relative;
  padding: 8px 12px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: #fff;

  color: ${({ theme }) => theme.colors.blueGray500};
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.36px;

  select {
    background-color: #fff;
    width: 100%;
    border: none;

    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 15px;
    font-weight: 500;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
    appearance: none;

    &:focus {
      outline: none;
    }
  }
`;

const Input = styled.input`
  flex: 1;
  width: 100%;
  padding: 8px 16px 8px 40px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.blueGray800};

  &::placeholder {
    color: ${({ theme }) => theme.colors.blueGray300};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
  }

  &:focus {
    outline: none;
  }
`;

const SearchIcon = () => {
  return (
    <div style={{ position: "absolute", left: 10, top: 10, cursor: "pointer" }}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.562 6.55169C13.4946 5.47485 12.0682 4.8892 10.5568 4.8892C9.04548 4.8892 7.61912 5.4843 6.55172 6.55169C5.47487 7.61909 4.88921 9.04544 4.88921 10.5568C4.88921 12.0682 5.48431 13.4945 6.55172 14.5619C7.61912 15.6387 9.04548 16.2244 10.5568 16.2244C12.0682 16.2244 13.4946 15.6293 14.562 14.5619C15.6388 13.4945 16.2245 12.0682 16.2245 10.5568C16.2245 9.04544 15.6294 7.61909 14.562 6.55169ZM5.21038 5.21036C6.69341 3.73679 8.62041 3 10.5568 3V3.00945C12.4933 3.00945 14.4297 3.74623 15.9033 5.21981C18.6108 7.92729 18.831 12.1893 16.5639 15.1496L20.7104 19.2962C21.1009 19.6867 21.1009 20.3199 20.7104 20.7104C20.3199 21.1009 19.6867 21.1009 19.2962 20.7104L15.1482 16.5624C13.7947 17.5965 12.1791 18.1136 10.5568 18.1136C8.62041 18.1136 6.68396 17.3768 5.21038 15.9032C2.26321 12.9466 2.26321 8.16696 5.21038 5.21036Z"
          fill="#808690"
        />
      </svg>
    </div>
  );
};

const ResultContainer = styled.div`
  overflow: scroll;
  max-height: 450px;

  ul {
    margin: 16px 0;

    li {
      padding: 9px 8px;
      border-radius: 8px;

      &:hover {
        cursor: pointer;
        background-color: #f4f6f8;
      }

      .address {
        margin-top: 2px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.02em;
        color: #666e7a;
      }
    }
  }
`;

const HightLight = styled.p`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #4d5664;

  em {
    color: ${({ theme }) => theme.colors.primaryBlue100};
  }
`;
