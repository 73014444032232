import { useGetPresignedUrl, usePostModal } from "@api/management/modal";
import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import { ButtonBox } from "@components/styles";
import { useAlert } from "@features/useAlert";
import { getAddressInfo } from "@utils/address";
import { uploadModalImageToS3 } from "@utils/file";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function ConfirmButton({ isDone, modalInfo }) {
  const navigate = useNavigate();
  const { showGlobalAlert } = useAlert();
  const { mutate: getPresignedUrl, isLoading: urlLoading } =
    useGetPresignedUrl();
  const { mutate, isLoading } = usePostModal();
  const handleConfirm = async () => {
    if (!modalInfo.file) return;
    if (modalInfo.startDate > modalInfo.endDate) {
      alert("종료일이 시작일보다 빠릅니다.");
      return;
    }
    if (modalInfo.deeplink) {
      if (
        modalInfo.deeplink.includes("danji/:id") ||
        modalInfo.deeplink.includes("maemul")
      ) {
        const data = await getAddressInfo({ danjiCode: modalInfo.deeplinkId });

        if (!data) {
          alert("유효한 단지코드가 아닙니다.");
          return;
        }
      }
    }
    if (modalInfo.externalUrl) {
      try {
        new URL(modalInfo.externalUrl);
      } catch (_) {
        alert("유효한 URL이 아닙니다.");
        return;
      }
    }

    const fileName = modalInfo.file.name;
    getPresignedUrl(
      { type: fileName.split(".").pop() },
      {
        onSuccess: async (data) => {
          const check = await uploadModalImageToS3({
            presignedObj: data.url,
            file: modalInfo.file,
          });
          if (check) {
            const { url, fields } = data.url;
            const requestBody = {
              imageUrl: `${url}${fields.key}`,
              startAt: moment(modalInfo.startDate)
                .add(9, "hours")
                .toISOString(),
              endAt: moment(modalInfo.endDate).add(9, "hours").toISOString(),
            };
            if (modalInfo.deeplink) {
              const newDeeplink = modalInfo.deeplink.replace(
                ":id",
                `{id:${modalInfo.deeplinkId}}`
              );
              requestBody["deepLink"] = newDeeplink;
            }
            if (modalInfo.externalUrl) {
              requestBody["externalUrl"] = modalInfo.externalUrl;
            }

            mutate(requestBody, {
              onSuccess: () => {
                showGlobalAlert({
                  desc: "정상적으로 등록이 되었습니다",
                  blue: true,
                  path: -1,
                });
              },
              onError: (error) => {
                showGlobalAlert({
                  desc: `[${error.response.data["message"]}]\n일시적인 오류로 삭제 실패했습니다.\n다시 시도해주세요.`,
                  blue: false,
                  path: -1,
                });
              },
            });
          } else {
            alert("이미지 업로드 url 생성 실패");
          }
        },
      }
    );
  };

  return (
    <>
      <ButtonBox style={{ marginTop: 20 }}>
        <button onClick={() => navigate(-1)}>취소</button>
        <button
          className={!isDone ? "disabled" : ""}
          disabled={!isDone}
          onClick={handleConfirm}
        >
          적용
        </button>
      </ButtonBox>
      {(isLoading || urlLoading) && <AbsoluteFillLoading />}
    </>
  );
}
