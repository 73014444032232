import styled from "styled-components";

export default function LineTabButton({ tabData, tabStatus, setTabStatus }) {
  return (
    <Container>
      <div className="line" />
      {tabData.map((el, idx) => (
        <Tab
          $active={tabStatus === el.value}
          onClick={() => setTabStatus(el.value)}
          key={idx}
        >
          <p>{el.title}</p>
        </Tab>
      ))}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 32px;
  margin-bottom: 56px;
  position: relative;
  width: 100%;

  &::before {
    position: absolute;
    content: "";
    right: 0;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.blueGray200};
    z-index: 0;
  }
`;

const Tab = styled.button<{ $active: boolean }>`
  position: relative;
  z-index: 1;
  width: 167px;
  height: 44px;
  border-top: 2px solid
    ${({ theme, $active }) => ($active ? theme.colors.blueGray800 : "white")};
  border-right: 1px solid
    ${({ theme, $active }) => ($active ? theme.colors.blueGray200 : "white")};
  border-left: 1px solid
    ${({ theme, $active }) => ($active ? theme.colors.blueGray200 : "white")};
  border-bottom: 1px solid
    ${({ theme, $active }) => ($active ? "white" : theme.colors.blueGray200)};
  background-color: white;

  p {
    color: ${({ theme, $active }) =>
      $active ? theme.colors.blueGray800 : theme.colors.blueGray500};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.32px;
  }
`;
