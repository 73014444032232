import { useState } from "react";
import styled from "styled-components";
import { Arrows18 } from "@components/core/icon/arrow";
import { ArrowDirectionEnum } from "@components/core/icon/arrow/Arrow.interfaces";

export default function TitleBox({ selectedFilter, setSelectedFilter }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
    setIsOpen(false);
  };

  return (
    <div>
      <SelectContainer>
        <Label onClick={toggleDropdown}>
          <p>{selectedFilter.label || "전체 상태"}</p>
          <Arrows18
            direction={isOpen ? ArrowDirectionEnum.UP : ArrowDirectionEnum.DOWN}
          />
        </Label>

        <DropdownContainer>
          {isOpen && (
            <DropdownList>
              {[
                { label: "전체 상태", value: null },
                { label: "대기 상태", value: "pending" },
                { label: "진행 상태", value: "in_progress" },
                { label: "만료 상태", value: "expired" },
              ].map((filter) => (
                <li
                  key={filter.label}
                  onClick={() => handleFilterClick(filter)}
                >
                  {filter.label}
                </li>
              ))}
            </DropdownList>
          )}
        </DropdownContainer>
      </SelectContainer>
    </div>
  );
}

const SelectContainer = styled.div`
  width: 100px;
  margin-top: 32px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;

  margin-bottom: 10px;
  padding: 8px 12px;
  border: 1px solid #e5e7e9;
  border-radius: 8px;

  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #4d5664;
  cursor: pointer;
`;

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;

  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 0;
  padding: 12px 8px;
  box-shadow: 0px 0px 20px 0px #00000014;
  border-radius: 16px;
  background-color: #ffffff;

  & li {
    padding: 4px 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #333e4d;
    cursor: pointer;

    &:hover {
      background-color: #e8f3ff;
      color: #1c84ff;
      border-radius: 8px;
    }
  }
`;
