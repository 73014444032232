import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import SendCheckPopup from "@components/page/admin/manage/push/SendPush/SendCheckPopup";
import SendPreview from "@components/page/admin/manage/push/SendPush/SendPreview";
import SendPushContent from "@components/page/admin/manage/push/SendPush/SendPushContent";
import { Title } from "@components/styles";
import { useMenu } from "@features/useMenu";
import { useCreatePushSchedule, usePostPush } from "@hooks/management";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import styled from "styled-components";

export default function AddPushSchedule() {
  const { setCurrentMenu } = useMenu();
  const navigate = useNavigate();
  const { mutate: nowMutate, isLoading: nowLoading } = usePostPush();
  const { mutate: afterMutate, isLoading: afterLoading } =
    useCreatePushSchedule();
  const [isVisible발송확인팝업, setIsVisible발송확인팝업] = useState(false);
  const [id입력여부, setId입력여부] = useState(false);

  useEffect(() => {
    setCurrentMenu("push-schedule");
  }, []);

  const [pushInfo, setPushInfo] = useState({
    method: "single",
    nickname: null,
    push_title: null,
    push_body: null,
    push_type: "direct",
    target: [],
    target_group: null, // 예약 발송
    schedule: null, // 예약 발송
    deeplink: null,
    id: "",
    screen_destination: null,
    needUpgradeNoti: false, // 즉시발송
    schedule_group: "admin",
  });

  const onSuccess = () => {
    navigate(-1);
    alert("발송 성공");
  };

  function replaceIdInString(template, newId) {
    if (!template) return;
    else return template.replace(":id", newId);
  }

  const 푸시_발송 = () => {
    const {
      method,
      nickname,
      target,
      target_group,
      schedule,
      needUpgradeNoti,
      push_type,
      deeplink,
      id,
      ...common
    } = pushInfo;

    const getBody = () => {
      const commonDeeplink = {
        deeplink: replaceIdInString(deeplink, id),
        ...common,
      };

      if (push_type === "scheduled") {
        const scheduledBody = { schedule, ...commonDeeplink };
        return method === "single"
          ? { target_group: target[0], ...scheduledBody }
          : { target_group, ...scheduledBody };
      } else {
        const immediateBody = { needUpgradeNoti, ...commonDeeplink };
        return method === "single"
          ? { target, ...immediateBody }
          : { target: target_group, ...immediateBody };
      }
    };

    const body = getBody();
    console.log("🪄  AddPushSchedule  body", body);

    const mutateFn = push_type === "scheduled" ? afterMutate : nowMutate;

    mutateFn(body, {
      onSuccess: onSuccess,
      // onError: () => alert("발송 실패"),
    });
  };

  const isDone = useMemo(() => {
    return (
      pushInfo.method &&
      (pushInfo.target.length > 0 || pushInfo.target_group) &&
      pushInfo.push_title &&
      id입력여부
    );
  }, [pushInfo, id입력여부]);

  const isPastOrWithinFiveMinutes = (selectedTime) => {
    const inputDate = new Date(selectedTime);
    const now = new Date();

    // 현재 시간으로부터 5분 후의 시간 계산
    const fiveMinutesLater = new Date(now.getTime() + 5 * 60 * 1000);

    // 입력된 시간이 현재보다 과거거나 5분 이내이면 true 반환
    return inputDate <= fiveMinutesLater;
  };

  const handleBottomBtn = () => {
    console.log(pushInfo);
    if (pushInfo.push_type === "direct") {
      setIsVisible발송확인팝업(true);
      return;
    }

    if (isPastOrWithinFiveMinutes(pushInfo.schedule)) {
      alert(
        "예약 발송 시점은 현재 시간보다 최소 5분 이후여야합니다. 다시 선택해주세요."
      );
      return;
    }

    setIsVisible발송확인팝업(true);
  };

  return (
    <Container>
      <Title>푸시 스케줄 생성</Title>
      <div style={{ height: 40 }} />
      <Row>
        <div style={{ width: "50%", maxWidth: 700 }}>
          <SendPushContent
            pushInfo={pushInfo}
            setPushInfo={setPushInfo}
            setId입력여부={setId입력여부}
          />
          <div
            style={{
              marginTop: 16,
              position: "relative",
              right: -10,
              width: "100%",
            }}
          >
            <CTAButton
              $isDone={isDone}
              disabled={!isDone}
              onClick={handleBottomBtn}
            >
              {nowLoading || afterLoading ? (
                <FadeLoader
                  cssOverride={{
                    width: 10,
                    height: 10,
                    transform: "scale(0.5)",
                    top: -2,
                    left: 0,
                  }}
                  color="white"
                />
              ) : (
                <p>등록하기</p>
              )}
            </CTAButton>
          </div>
        </div>
        <div
          style={{
            position: "sticky",
            top: "10%",
            height: "200%",
            width: "50%",
          }}
        >
          <SendPreview pushInfo={pushInfo} />
        </div>
      </Row>
      <PopUpContainer
        isVisible={isVisible발송확인팝업}
        setIsVisible={setIsVisible발송확인팝업}
      >
        <SendCheckPopup
          pushInfo={pushInfo}
          postPush={푸시_발송}
          setIsVisible발송확인팝업={setIsVisible발송확인팝업}
        />
      </PopUpContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-width: 1200px;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 100px;
`;

const CTAButton = styled.button<{ $isDone: boolean }>`
  padding: 10px 0;
  width: 100%;
  border-radius: 8px;
  border: none;
  background: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.primaryBlue100 : theme.colors.blueGray100};
  cursor: pointer;
  color: ${({ theme, $isDone }) =>
    $isDone ? theme.colors.white : theme.colors.blueGray500};
  font-size: 18px;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;

  &:disabled {
    cursor: not-allowed;
  }
`;
