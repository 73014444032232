import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import DevNavContainer from "@components/nav/DevNavContainer";
import { useGetMe } from "@api/user";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { SyncLoader } from "react-spinners";

export default function DevLayout() {
  const { data, isLoading } = useGetMe();

  if (isLoading || !data) {
    return (
      <PopUpContainer isVisible={true} setIsVisible={() => {}}>
        <SyncLoader />
        <p
          style={{
            marginTop: 20,
            fontSize: 20,
            color: "white",
            fontWeight: 500,
          }}
        >
          권한 확인 중
        </p>
      </PopUpContainer>
    );
  }

  if (data.user_group !== "개발자") {
    window.location.replace("/");
    return <></>;
  }

  return (
    <Container>
      {/* GNB */}
      <DevNavContainer />
      {/* 그 외 컨텐츠 */}
      <OutletWrapper>
        <div style={{ maxWidth: 1440, minWidth: 1440 }}>
          <Outlet />
        </div>
      </OutletWrapper>
    </Container>
  );
}

// viewport 화면 전체
const Container = styled.div``;

const OutletWrapper = styled.main`
  margin: 0 auto;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 110px 0 0px;
  min-width: 1560px;
  max-width: 1560px;
  height: 100%;
`;
