import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import styled, { CSSProperties, keyframes } from "styled-components";

interface Props {
  children: any;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>> | (() => void) | null;
  style?: CSSProperties;
  canBackdropClose?: boolean;
}

export const PopUpContainer = ({
  children,
  isVisible,
  setIsVisible,
  style,
  canBackdropClose = true,
}: Props) => {
  const [isRendered, setIsRendered] = useState(false);
  const popUpRef = useRef(null);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
      setIsRendered(true);

      setTimeout(() => {
        if (popUpRef.current) {
          popUpRef.current.style.bottom = "0px";
        }
      }, 100);
    } else {
      if (popUpRef.current) {
        document.body.style.overflow = "auto";
        popUpRef.current.style.bottom = "-1000px";
      }

      setTimeout(() => {
        setIsRendered(false);
      }, 100);
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isVisible]);

  if (!isRendered) return null;

  const handleBackdrop = () => {
    if (canBackdropClose) setIsVisible(false);
  };

  return ReactDOM.createPortal(
    <PopUpBackdrop $isVisible={isVisible} onClick={handleBackdrop}>
      <PopUpContent
        $isVisible={isVisible}
        ref={popUpRef}
        style={{ ...style }}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </PopUpContent>
    </PopUpBackdrop>,
    document.getElementById("portal-root")
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  50% {
    opacity: 0.5;
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  50% {
    opacity: 0.8;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
`;

const appearBackdrop = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`;

const disappearBackdrop = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
`;

const PopUpBackdrop = styled.div<{ $isVisible: boolean }>`
  z-index: 10000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bgColor};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: ${(props) =>
      props.$isVisible ? appearBackdrop : disappearBackdrop}
    0.1s ease-out forwards;
`;

const PopUpContent = styled.div<{ $isVisible: boolean }>`
  animation: ${(props) => (props.$isVisible ? fadeIn : fadeOut)} 0.2s ease-out
    forwards;
`;
