import { useState, useEffect, useMemo } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import moment from "moment";
import styled from "styled-components";
import {
  useGetInfluencersList,
  useUpsertReportHtml,
  useGetReportDetail,
} from "@hooks/admin";
import { useGetConsultingList } from "@hooks/consultation";

import { BTypeText48, TextInputEnum } from "@components/input/BTypeText48";
import { InputTypeEnum } from "@components/core/BTypeInput/BTypeInput.interfaces";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import BTypeInputBoxBtn from "@components/core/BTypeInput/BtypeInputBoxBtn";
import Section from "../Section";
import { Arrows22 } from "@components/core/icon/arrow";
import {
  ArrowDirectionEnum,
  ArrowColorEnum,
} from "@components/core/icon/arrow/Arrow.interfaces";
import { Close24 } from "@components/core/icon/close";
import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";

export default function BasicInfo({
  contentId,
  reportInfo,
  setReportInfo,
  additional,
  setAdditional,
}) {
  const [isShown, setIsShown] = useState(true);
  const [influencerList, setInfluencerList] = useState([
    { label: "", value: "" },
  ]);
  const [consultingList, setConsultingList] = useState([
    { label: "", value: "" },
  ]);
  const { mutate: html재생성 } = useUpsertReportHtml();
  const { mutate: 레포트상세조회 } = useGetReportDetail();
  const { data: influencerListData } = useGetInfluencersList();
  const { data: consultingListData } = useGetConsultingList({
    dbVersion: process.env.REACT_APP_ENV,
  });
  const [buttonLoading, setButtonLoading] = useState(false);

  const html재생성disabled = useMemo(() => {
    if (!reportInfo) return true;

    return !(reportInfo.page_url && reportInfo.title) || buttonLoading;
  }, [reportInfo, buttonLoading]);

  useEffect(() => {
    if (influencerListData) {
      const arr = influencerListData.influencers.map((el) => {
        return { label: el.nickname, value: el.nickname };
      });
      setInfluencerList(arr);
    }
  }, [influencerListData]);

  useEffect(() => {
    if (consultingListData) {
      const arr = consultingListData.consultings.map((el) => {
        return { label: el.influencer.name, value: el.consultingId };
      });
      setConsultingList(arr);
    }
  }, [consultingListData]);

  const handleAdditional = (e) => {
    setAdditional(e.target.value);

    if (e.target.value === "none") {
      setReportInfo((prev) => ({
        ...prev,
        consulting_id: undefined,
        danji_list: null,
      }));
    }

    if (e.target.value === "consulting_id") {
      setReportInfo((prev) => ({ ...prev, danji_list: null }));
      return;
    }

    if (e.target.value === "danji_list") {
      setReportInfo((prev) => ({ ...prev, consulting_id: undefined }));
      return;
    }
  };

  const openSearchDanji = () => {
    const left = window.screenX + 100; // 현재 창의 X 좌표 기준
    const top = window.screenY + 100; // 현재 창의 Y 좌표 기준

    window.open(
      `/search/danji`,
      "popupWindow", // 창 이름
      `width=${490},height=${600},left=${left},top=${top},scrollbars=no` // 창 크기 및 옵션 설정
    );
  };

  useEffect(() => {
    const handleStorage = (event) => {
      if (event.key === "selected_danji") {
        const currentDanjiList = reportInfo.danji_list;
        const newDanji = JSON.parse(event.newValue);

        if (!currentDanjiList) {
          setReportInfo((prev) => ({
            ...prev,
            danji_list: [newDanji],
          }));
        } else {
          const filtered = currentDanjiList.filter(
            (v) => v.scaleCode !== newDanji.scaleCode
          );
          const mergedDanjiList = [...filtered, newDanji];

          setReportInfo((prev) => ({
            ...prev,
            danji_list: mergedDanjiList,
          }));
        }
      }
    };

    window.addEventListener("storage", handleStorage);

    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, [reportInfo.danji_list]);

  const 추천단지삭제 = (scaleCode) => {
    const currentDanjiList = reportInfo.danji_list;

    const filtered = currentDanjiList.filter((v) => v.scaleCode !== scaleCode);
    setReportInfo((prev) => ({ ...prev, danji_list: filtered }));
  };

  const html_재생성 = () => {
    setButtonLoading(true);

    html재생성(
      {
        content_id: contentId,
        data: {
          page_url: reportInfo.page_url,
          title: reportInfo.title,
        },
      },
      {
        onSuccess() {
          let attempt = 0;
          const intervalId = setInterval(() => {
            attempt++;
            레포트상세조회(
              { content_id: contentId },
              {
                onSuccess: (detail) => {
                  if (detail.html) {
                    // 특정 값 체크
                    clearInterval(intervalId); // 성공 시 Interval 제거
                    setButtonLoading(false);

                    const left = window.screenX + 100; // 현재 창의 X 좌표 기준
                    const top = window.screenY + 100; // 현재 창의 Y 좌표 기준

                    window.open(
                      `/preview/weekly/${detail.content_id}`,
                      "popupWindow", // 창 이름
                      `width=${900},height=${722},left=${left},top=${top},scrollbars=no` // 창 크기 및 옵션 설정
                    );
                  }
                },
                onError: () => {
                  alert("레포트 정보 조회 실패");
                  clearInterval(intervalId);
                  setButtonLoading(false);
                },
              }
            );

            if (attempt >= 30) {
              clearInterval(intervalId); // Interval 제거
              alert("최대 대기 시간 초과.\n개발팀에 문의하세요.");
              setButtonLoading(false);
            }
          }, 2000); // 2초마다 실행
        },
        onError() {
          alert("html 재생성에 실패했습니다.\n개발팀에 문의하세요.");
          setButtonLoading(false);
        },
      }
    );
  };

  return (
    <Container>
      <PageUrlContainer>
        <BTypeText48
          placeholder="제목 입력"
          textType={TextInputEnum.DEFAULT}
          value={reportInfo.page_url}
          onChangeValue={(value) =>
            setReportInfo((prev) => ({ ...prev, page_url: value }))
          }
        />
        <button
          onClick={html_재생성}
          disabled={html재생성disabled}
          style={{
            backgroundColor: html재생성disabled ? "#B2D6FF" : "#1C84FF",
          }}
        >
          {buttonLoading ? "로딩중..." : "html 재생성"}
        </button>
      </PageUrlContainer>
      <InnerContainer>
        <TitleBox onClick={() => setIsShown(!isShown)}>
          <div style={{ display: "flex" }}>
            <h3>레포트 정보 등록</h3>
            <Star />
          </div>
          <Arrows22
            direction={
              isShown ? ArrowDirectionEnum.UP : ArrowDirectionEnum.DOWN
            }
            color={ArrowColorEnum.B50}
          />
        </TitleBox>
        {isShown && (
          <InputContainer>
            <Section
              title="제목"
              bodyComponent={
                <div>
                  <BTypeText48
                    placeholder="제목 입력"
                    textType={TextInputEnum.DEFAULT}
                    value={reportInfo.title}
                    onChangeValue={(value) =>
                      setReportInfo((prev) => ({ ...prev, title: value }))
                    }
                    containerStyle={{ height: "auto" }}
                  />
                  <TitleMessage>
                    제목 수정 후 꼭 html 재 생성 후 저장해야 레포트에
                    반영됩니다.
                  </TitleMessage>
                </div>
              }
            />
            <Section
              title="작성자"
              bodyComponent={
                <BTypeInputBoxBtn
                  inputType={InputTypeEnum.OnlyText}
                  value={reportInfo.influencer}
                  onChangeValue={(e) => {
                    setReportInfo((prev) => ({
                      ...prev,
                      influencer: e.target.value,
                    }));
                  }}
                  style={{ padding: 0 }}
                  inputStyle={{ marginTop: 0, height: 48 }}
                  listData={[
                    { label: "활동명", value: null },
                    ...influencerList,
                  ]}
                  selectStyle={{ fontSize: 15 }}
                />
              }
            />
            <Section
              title="한 줄 요약"
              bodyComponent={
                <BTypeTextArea
                  placeholder="레포트 요약을 작성해주세요"
                  value={reportInfo.new_reports_desc}
                  onChangeValue={(value) =>
                    setReportInfo((prev) => ({
                      ...prev,
                      new_reports_desc: value,
                    }))
                  }
                  textareaStyle={{ height: 120, fontSize: 15 }}
                />
              }
            />
            <Section
              title="부가 기능"
              bodyComponent={
                <div>
                  <div style={{ display: "flex" }}>
                    <BTypeInputBoxBtn
                      inputType={InputTypeEnum.OnlyText}
                      value={additional}
                      onChangeValue={handleAdditional}
                      style={{ padding: 0, flex: 2 }}
                      inputStyle={{ marginTop: 0, height: 48 }}
                      listData={[
                        { label: "부가기능을 선택해주세요", value: null },
                        { label: "기능 없음", value: "none" },
                        {
                          label: "컨설턴트 상세 페이지로 이동",
                          value: "consulting_id",
                        },
                        { label: "추천 단지 정보 입력", value: "danji_list" },
                      ]}
                      selectStyle={{ fontSize: 15 }}
                    />
                    <BTypeInputBoxBtn
                      inputType={InputTypeEnum.OnlyText}
                      value={reportInfo.consulting_id}
                      onChangeValue={(e) => {
                        setReportInfo((prev) => ({
                          ...prev,
                          consulting_id: e.target.value,
                        }));
                      }}
                      style={{ padding: 0, flex: 1, marginLeft: 8 }}
                      inputStyle={{ marginTop: 0, height: 48 }}
                      listData={[
                        { label: "부가 기능을 선택해주세요", value: undefined },
                        ...consultingList,
                      ]}
                      selectStyle={{ fontSize: 15 }}
                      disabled={additional !== "consulting_id"}
                    />
                  </div>
                  {additional === "danji_list" && (
                    <DanjiListContainer>
                      <DanjiListTitleBox onClick={openSearchDanji}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <PlusIcon />
                          <h3>추천 단지 추가하기</h3>
                        </div>
                      </DanjiListTitleBox>
                      <DanjiListBox>
                        {reportInfo.danji_list?.map((v, i) => (
                          <DanjiListItem
                            key={v.scaleCode}
                            style={{
                              borderWidth: i === 0 ? 0 : 1,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <DanjiName>{v.danjiInfo?.대표단지명}</DanjiName>
                                <span
                                  style={{ margin: "0 2px", color: "#E5E7E9" }}
                                >
                                  |
                                </span>
                                <Pyeong>{v.pyeongInfo?.평타입}평</Pyeong>
                              </div>
                              <Address>
                                {v.danjiInfo?.도로명주소 ||
                                  v.danjiInfo?.법정동주소}
                              </Address>
                            </div>
                            <div
                              onClick={() => 추천단지삭제(v.scaleCode)}
                              style={{ padding: "0 8px", cursor: "pointer" }}
                            >
                              <Close24 />
                            </div>
                          </DanjiListItem>
                        ))}
                      </DanjiListBox>
                    </DanjiListContainer>
                  )}
                </div>
              }
            />
            <Section
              title="등록 일시"
              bodyComponent={
                <PickerContainer>
                  <DatePicker
                    selected={
                      reportInfo.release_date
                        ? new Date(reportInfo.release_date)
                        : null
                    }
                    onChange={(v: Date) => {
                      setReportInfo((prev) => ({
                        ...prev,
                        release_date: new Date(v).toISOString(),
                      }));
                    }}
                    dateFormat="yyyy.MM.dd"
                    minDate={new Date()}
                    locale={ko}
                    timeFormat="HH:mm"
                    showTimeSelect
                    shouldCloseOnSelect
                    onCalendarClose={() => {}}
                    customInput={
                      <DateInputContainer>
                        {reportInfo.release_date ? (
                          <div className="value">
                            {moment(reportInfo.release_date).format(
                              "YYYY-MM-DD HH:mm"
                            )}
                          </div>
                        ) : (
                          <div className="placeholder">날짜 선택</div>
                        )}
                      </DateInputContainer>
                    }
                  />
                  <DateMessage>
                    레포트가 라이브 앱에 보이는 시간을 설정할 수 있습니다.
                  </DateMessage>
                </PickerContainer>
              }
            />
          </InputContainer>
        )}
      </InnerContainer>
      {buttonLoading && <AbsoluteFillLoading />}
    </Container>
  );
}

const Container = styled.div`
  padding: 12px 0;
`;

const InnerContainer = styled.div`
  border: 1px solid #e5e7e9;
  border-radius: 16px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  height: 64px;
  padding: 19px 16px;

  h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #333e4d;
  }

  &:hover {
    cursor: pointer;
  }
`;

const PageUrlContainer = styled.div`
  position: relative;

  button {
    position: absolute;
    top: 6px;
    right: 6px;

    background-color: #1c84ff;
    height: 36px;
    padding: 7px 16px;
    border-radius: 8px;

    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #fff;
  }
`;

const InputContainer = styled.div`
  border-top: 1px solid #e5e7e9;
`;

const Star = styled.p`
  margin: 4px 0 0 2px;

  &::after {
    color: #f04452;
    content: "*";
  }
`;

const TitleMessage = styled.div`
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #808690;
`;

const DanjiListContainer = styled.div`
  margin-top: 8px;
  border: 1px solid #e5e7e9;
  border-radius: 16px;
  overflow-y: scroll;
`;

const DanjiListTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  height: 48px;
  padding: 12px 16px;

  h3 {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #666e7a;
  }

  &:hover {
    cursor: pointer;
  }
`;

const DanjiListBox = styled.ul`
  border-top: 1px solid #e5e7e9;
  height: 224px;
`;

const DanjiListItem = styled.li`
  margin: 0 16px;
  padding: 16px 0;
  border-top: 1px solid #f0f2f5;
`;

const DanjiName = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #4d5664;
`;

const Pyeong = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #4d5664;
`;

const Address = styled.p`
  margin-top: 2px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #666e7a;
`;

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect x="7" y="3" width="2" height="10" fill="#808690" />
      <rect
        x="13"
        y="7"
        width="2"
        height="10"
        transform="rotate(90 13 7)"
        fill="#808690"
      />
    </svg>
  );
};

const PickerContainer = styled.div`
  padding-bottom: 20px;

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const DateInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  .placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }

  .value {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;

const DateMessage = styled.p`
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #808690;
`;
