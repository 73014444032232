export const transformAddressInfo = (
  danjiInfo
): { houseName: string; houseAddress: string } => {
  if (!danjiInfo) {
    return { houseName: "", houseAddress: "" };
  }

  const { 단지코드, 대표단지명, 도로명주소, 법정대표동, 법정동주소 } =
    danjiInfo;
  if (단지코드 === -1) {
    // 직접 입력한 집 1. 도로명 주소 2. 구주소
    return {
      houseName: 대표단지명 || 도로명주소,
      houseAddress: 도로명주소 ? 법정동주소 : 도로명주소,
    };
  }
  if (단지코드 < 1000000) {
    // 아파트일 때 1. 집 이름, 2. 도로명주소 (도로명주소 없을 때 구주소)
    return {
      houseName: `${법정대표동} ${대표단지명}`,
      houseAddress: 도로명주소 || 법정동주소,
    };
  } else if (단지코드 >= 1000000) {
    // 빌라일 때 1. 도로명주소 (도로명주소 없을 때 구주소) 2. 구주소 (도로명주소 없을 때 비움))
    return {
      houseName: `${도로명주소 ? 도로명주소 : 법정동주소} (${대표단지명})`,
      houseAddress: 도로명주소 ? 법정동주소 : "",
    };
  } else {
    return { houseName: "", houseAddress: "" };
  }
};

export const convertAddressToCoord = async (address) => {
  try {
    const response = await fetch(
      `https://dapi.kakao.com/v2/local/search/address.json?query=${address}`,
      {
        headers: {
          Authorization: "KakaoAK 5a5a95674b28d04a1b2828b44acc72dd",
        },
      }
    );
    const data = await response.json();
    return data.documents[0] || null;
  } catch (e) {
    return undefined;
  }
};

export const getAddressInfo = async ({ danjiCode }: { danjiCode: string }) => {
  try {
    const response = await fetch(
      `https://live.boodong.co.kr/danjis/info/address_info?danjiCode=${danjiCode}`
    );

    if (!response.ok) {
      return null;
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching address info:", error);
    alert("주소 정보를 가져오는 중 오류가 발생했습니다.");
    throw error;
  }
};
