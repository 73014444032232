import { Title } from "@components/styles";
import styled from "styled-components";
import QnaInput from "./QnaInput";
import {
  useGetInquiryById,
  useGetPushAgreeCheck,
  usePostInquiryAnswer,
} from "@api/management/qna";
import { useEffect, useState } from "react";
import ModalContainer from "@components/core/modal/ModalContainer";
import PushCheckModal from "./PushCheckModal";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { useParams } from "react-router-dom";
import { usePostPush } from "@hooks/management";
import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";

export default function EditQnaPage() {
  const { id } = useParams();
  const { data, isLoading } = useGetInquiryById({ id });
  const [isVisible푸시전송팝업, setIsVisible푸시전송팝업] = useState(false);
  const [qnaInfo, setQnaInfo] = useState({ answer: null });
  const [status, setStatus] = useState<
    "푸시여부" | "로딩중" | "등록완료" | "푸시불가능" | "푸시완료"
  >("등록완료");
  const { mutate, isLoading: postInquiryLoading } = usePostInquiryAnswer();
  const { mutate: pushAgreeCheck } = useGetPushAgreeCheck();
  const { mutate: sendPush } = usePostPush();

  useEffect(() => {
    if (!data) return;

    setQnaInfo({ answer: data.answer });
  }, [data]);

  const 답변등록 = () => {
    mutate(
      { id, answer: qnaInfo.answer },
      { onSuccess: () => setIsVisible푸시전송팝업(true) }
    );
  };

  const 푸시전송가능확인 = () => {
    // fcm 토큰을 정상적으로 받았다면 모두 ok
    pushAgreeCheck(
      { userId: data.userId },
      {
        onSuccess: (res) => {
          if (res.consent) {
            sendPush(
              {
                target: [data.userId],
                needUpgradeNoti: false,
                deeplink: "inquiry/1",
                push_title: "💌 문의하신 내용에 관리자가 답변을 남겼어요",
                push_body: "답변을 확인해보세요.",
                schedule_group: "admin",
              },
              { onSuccess: () => setStatus("푸시완료") }
            );
          } else setStatus("푸시불가능");
        },
        onError: () => setStatus("푸시불가능"),
      }
    );
  };

  if (!data) return isLoading && <AbsoluteFillLoading />;

  return (
    <Container>
      <Title>1:1 문의 답변 관리</Title>
      <QnaInput
        data={data}
        qnaInfo={qnaInfo}
        setQnaInfo={setQnaInfo}
        handleConfirm={답변등록}
      />
      <PopUpContainer isVisible={isVisible푸시전송팝업} setIsVisible={() => {}}>
        <PushCheckModal
          답변등록={답변등록}
          푸시전송가능확인={푸시전송가능확인}
          모달닫기={() => setIsVisible푸시전송팝업(false)}
          status={status}
          setStatus={setStatus}
        />
      </PopUpContainer>
      {/* {(isLoading || postInquiryLoading) && <AbsoluteFillLoading />} */}
    </Container>
  );
}

const Container = styled.div`
  padding: 0 243px 60px;
`;
