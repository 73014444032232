import { useEffect, useState } from "react";
import styled from "styled-components";
import Example from "../assets/ExampleImg.png";
import LoadingAnimation from "../../../../assets/lottie/Loading.json";
import 성공 from "../../../../assets/lottie/성공.json";
import 실패 from "../../../../assets/lottie/실패.json";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";

export default function PushCheckModal({
  답변등록,
  푸시전송가능확인,
  모달닫기,
  status,
  setStatus,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (status === "로딩중") {
      푸시전송가능확인();
    }
  }, [status]);

  const 이전화면 = () => {
    모달닫기();
    navigate(-1);
  };

  switch (status) {
    case "등록완료":
      return (
        <FlexContainer>
          <LottieBox>
            <Lottie
              animationData={성공}
              loop={false}
              style={{ width: 72, height: 72 }}
            />
            <h5>답변 등록 완료</h5>
          </LottieBox>
          <Button onClick={() => setStatus("푸시여부")}>다음</Button>
        </FlexContainer>
      );
    case "푸시여부":
      return (
        <Container>
          <Title>
            문의자가 관리자의 답변을 알 수 있도록{"\n"}푸시 메세지를 전송할까요?
          </Title>
          <SubTitle>푸시 타이틀은 아래와 같이 전송됩니다.</SubTitle>
          <Center>
            <img
              src={Example}
              alt="exampleImg"
              style={{ width: 268, height: 310 }}
            />
            <div className="blur" />
            <div className="box" />
            <ButtonBox>
              <button className="lightSolid" onClick={이전화면}>
                푸시 보내지 않기
              </button>
              <button className="solid" onClick={() => setStatus("로딩중")}>
                푸시 보내기
              </button>
            </ButtonBox>
          </Center>
        </Container>
      );
    case "로딩중":
      return (
        <Container>
          <LoadingCenter>
            <Lottie animationData={LoadingAnimation} loop={true} />
            <p>잠시만 기다려주세요.</p>
          </LoadingCenter>
        </Container>
      );
    case "푸시완료":
      return (
        <FlexContainer>
          <LottieBox>
            <Lottie
              animationData={성공}
              loop={false}
              style={{ width: 72, height: 72 }}
            />
            <h5>푸시 전송 완료!</h5>
            <p>문의자에게 답변 및 푸시를 보냈어요</p>
          </LottieBox>
          <Button onClick={이전화면}>닫기</Button>
        </FlexContainer>
      );
    case "푸시불가능":
      return (
        <FlexContainer>
          <LottieBox>
            <Lottie
              animationData={실패}
              loop={false}
              style={{ width: 72, height: 72 }}
            />
            <h5>
              해당 유저가 푸시 알림 동의를 {"\n"}하지 않아 발송할 수 없어요
            </h5>
          </LottieBox>
          <Button onClick={이전화면}>돌아가기</Button>
        </FlexContainer>
      );
  }
}

const Container = styled.div`
  padding: 24px 16px 20px;
  background-color: white;
  border-radius: 20px;
  width: 426px;
  height: 453px;
`;

const FlexContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.blueGray1000};
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px; /* 150% */
  letter-spacing: -0.4px;
  white-space: pre-wrap;
`;

const SubTitle = styled.h4`
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.blueGray600};
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.3px;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;

  img {
    position: absolute;
    bottom: 0;
  }

  .blur {
    position: absolute;
    left: 16px;
    bottom: 12px;
    width: 90%;
    height: 70px;
    background: #fff;
    filter: blur(10px);
  }

  .box {
    position: absolute;
    bottom: 0;
    height: 30px;
    width: 80%;
    background-color: white;
  }
`;

const ButtonBox = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 0 16px 20px;
  gap: 8px;
  /* background-color: white; */

  button {
    flex: 1;
    padding: 16px;
    border-radius: 10px;
    background: var(--Blue100, #e8f3ff);

    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }

  .lightSolid {
    background-color: ${({ theme }) => theme.colors.blue100};
    color: ${({ theme }) => theme.colors.primaryBlue100};
  }

  .solid {
    background-color: ${({ theme }) => theme.colors.primaryBlue100};
    color: white;
  }
`;

const LoadingCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: ${({ theme }) => theme.colors.blueGray700};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;

const LottieBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h5 {
    margin-top: 32px;
    color: ${({ theme }) => theme.colors.blueGray800};
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
    white-space: pre-wrap;
  }

  p {
    margin-top: 12px;
    color: ${({ theme }) => theme.colors.blueGray600};
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
`;

const Button = styled.button`
  margin-top: auto;
  padding: 16px 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primaryBlue100};
  border-radius: 10px;

  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
`;
