import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CirclePlus28, CircleMinus28 } from "@components/core/icon/plus";
import ModalContainer from "@components/core/modal/ModalContainer";
import GradientButton from "@components/button/GradientButton";
import { useDeleteSeries } from "@hooks/admin";

export default function SeriesListItem(data) {
  const navigate = useNavigate();
  const [showsDetail, setShowsDetail] = useState(false);
  const [삭제모달상태, 삭제모달상태변경] = useState(false);
  const { mutate: 시리즈_삭제 } = useDeleteSeries();

  const 자세히보기 = () => {
    navigate(`/content/weekly/series/edit/${data.series_id}`);
  };

  const 시리즈삭제 = () => {
    삭제모달상태변경(false);
    시리즈_삭제(
      { series_id: data.series_id },
      {
        onSuccess() {
          alert("삭제 성공");
          삭제모달상태변경(false);
        },
        onError() {
          alert("삭제 실패");
        },
      }
    );
  };

  return (
    <Container>
      <TitleBox onClick={() => setShowsDetail(!showsDetail)}>
        <Title>{data.title}</Title>
        {showsDetail ? <CircleMinus28 /> : <CirclePlus28 />}
      </TitleBox>
      {showsDetail && (
        <DetailContainer>
          <Desc>{data.desc}</Desc>
          <ReportList>
            {data.report_list.map((v) => (
              <ReportItem key={v.title}>↳ {v.title}</ReportItem>
            ))}
          </ReportList>
          <ButtonContainer>
            <button onClick={자세히보기}>자세히 보기</button>
            <button onClick={() => 삭제모달상태변경(true)}>시리즈 삭제</button>
          </ButtonContainer>
        </DetailContainer>
      )}
      <ModalContainer
        isVisible={삭제모달상태}
        setIsVisible={삭제모달상태변경}
        title="등록한 정보를 삭제할까요?"
        onCloseButton
        bottomButton={
          <GradientButton
            style={{ backgroundColor: "#F04452" }}
            text={"삭제하기"}
            isDone
            onClick={시리즈삭제}
          />
        }
      >
        <Content>
          <h3>아래 사항을 확인하세요</h3>
          <ul>
            <li>시리즈에 등록된 컨텐츠가 모두 삭제됩니다.</li>
            <li>시리즈의 순번이 하나씩 밀려 정렬됩니다.</li>
          </ul>
        </Content>
      </ModalContainer>
    </Container>
  );
}

const Container = styled.li`
  padding: 20px 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.blueGray100}`};
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.h4`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.blueGray800};
`;

const DetailContainer = styled.div``;

const Desc = styled.div`
  margin-top: 6px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.blueGray600};
`;

const ReportList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  gap: 6px;
`;

const ReportItem = styled.li`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.blueGray700};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    padding: 0 30px;
    height: 40px;
    border: ${({ theme }) => `1px solid ${theme.colors.blueGray700}`};

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.colors.blueGray700};
  }
`;

const Content = styled.div`
  padding: 0 8px 30px;
  width: 344px;
  height: 160px;
  overflow-y: auto;

  h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #333e4d;
    margin-bottom: 8px;
  }

  li {
    display: flex;
    align-items: center;
    color: #4d5664;
    height: 32px;

    &::before {
      content: "⦁";
      margin-right: 8px;
    }

    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
  }
`;
