import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthInstance, AuthInstanceWithCredentials, Instance } from "../../api";
import { AxiosError } from "axios";
import { InfluencerUsers } from "@api/contributor/contributor.interfaces";

// index

interface Content {
  title: string;
  released_at: string;
}

interface User {
  name: string;
  nickname: string;
  email: string;
  phone_number: string;
  memo: string;
  is_deleted?: boolean;
}

interface Payment {
  id?: number;
  content?: number;
  paid_at: string;
  price: number | string;
}

interface ContentsProps {
  start?: string;
  end?: string;
}

interface Error {
  response: { data: { message: string } };
}

// 컨텐츠 관리
export const useGetContentsList = (range) =>
  useQuery({
    queryKey: ["contentsList", range.start, range.end],
    queryFn: async () => {
      const response = await AuthInstance({
        url: `/contents?start_date=${range.start}&end_date=${range.end}`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 60 * 1000 * 5,
  });

// 개별 컨텐츠 조회
export const useGetContent = ({ id }) =>
  useQuery({
    queryKey: ["content", id],
    queryFn: async () => {
      const response = await AuthInstance({
        url: `/contents/${id}`,
        method: "GET",
      });
      return response.data;
    },
    staleTime: 1000 * 5,
    enabled: !!id,
  });

// 컨텐츠 제목 및 발행일 수정
export const useEditContent = () => {
  const queryClient = useQueryClient();
  return useMutation<
    { message: string; content: Content },
    Error,
    { title: string; released_at: string; id: number }
  >({
    mutationFn: async ({ id, ...body }) => {
      const response = await AuthInstance({
        url: `/contents/${id}`,
        method: "PATCH",
        data: body,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contentsList"],
      });
    },
  });
};

// 제출된 레포트 삭제 (정산과 엮여있어 완전 삭제는 아니고 숨기는 것임)
export const useDeleteContent = () => {
  const queryClient = useQueryClient();
  return useMutation<undefined, AxiosError, { id: string }>({
    mutationFn: async ({ id }: { id: string }) => {
      const response = await AuthInstance({
        url: `/contents/${id}`,
        method: "DELETE",
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contentsList"],
      });
    },
  });
};

export const usePostContent = () => {
  const queryClient = useQueryClient();
  return useMutation<
    { message: string; content: Content },
    AxiosError,
    {
      influencer_id: string;
      content: { title: string; page_url: string; submitted_at: string };
    }
  >({
    mutationFn: async (data) => {
      const response = await AuthInstance({
        url: `/administrators/contents`,
        method: "POST",
        data,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["contentsList"],
      });
    },
  });
};
// 회원 및 권한 관리
export const useGetAdminsList = () =>
  useQuery({
    queryKey: ["administratorList"],
    queryFn: async () => {
      const response = await AuthInstance({
        url: `/administrators`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 60 * 1000 * 24,
  });

export const useGetInfluencersList = () =>
  useQuery<any, AxiosError, InfluencerUsers>({
    queryKey: ["influencerList"],
    queryFn: async () => {
      const response = await AuthInstance({
        url: `/influencers`,
        method: "GET",
      });
      return response.data;
    },
    retry: false,
    staleTime: 0,
  });

export const useGetInfluencerInfo = () =>
  useMutation<{ message: string; influencer: User }, Error, { id: string }>(
    async ({ id }) => {
      const response = await AuthInstance({
        url: `/influencers/${id}`,
        method: "GET",
      });
      return response.data;
    }
  );

export const usePostInfluencer = () =>
  useMutation<object[], Error, User>(async (data) => {
    const response = await AuthInstance({
      url: `/influencers`,
      method: "POST",
      data,
    });
    return response.data;
  });

export const useEditInfluencer = () =>
  useMutation<object[], Error, { id: string; data: User }>(
    async ({ id, data }) => {
      const response = await AuthInstance({
        url: `/influencers/${id}`,
        method: "PATCH",
        data,
      });
      return response.data;
    }
  );

export const useDeleteInfluencer = () => {
  const queryClient = useQueryClient();
  return useMutation<object[], Error, { id: number | string }>({
    mutationFn: async ({ id }) => {
      const response = await AuthInstance({
        url: `/influencers/${id}`,
        method: "DELETE",
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["influencerList"],
      });
    },
  });
};

// 정산 여부로 구분하는 컨텐츠 조회
export const useGetContentPayments = () =>
  useMutation<
    { message: string; contents: object[] },
    Error,
    { start_date?: string; end_date?: string; is_paid?: boolean }
  >(async ({ start_date = "", end_date = "", is_paid }) => {
    const response = await AuthInstance({
      url: `/contents/payments?start_date=${start_date}&end_date=${end_date}&is_paid=${is_paid}`,
      method: "GET",
    });

    return response.data;
  });

export const usePostContentPayments = () =>
  useMutation<string, Error, { payments: Payment }>(async (data) => {
    const response = await AuthInstance({
      url: `/payments/list`,
      method: "POST",
      data,
    });

    return response.data;
  });

export const useEditContentPayments = () =>
  useMutation<string, Error, { payments: [Payment] }>(async (data) => {
    const response = await AuthInstance({
      url: `/payments/list`,
      method: "PATCH",
      data,
    });

    return response.data;
  });

// 인플루언서 정산 목록 조회
export const useGetInfluencerPaymentsList = () =>
  useMutation<
    { totalPayments: number; influencers: object[] },
    Error,
    { start_date?: string; end_date?: string }
  >(async ({ start_date = "", end_date = "" }) => {
    const response = await AuthInstance({
      url: `/influencers/payments?start_date=${start_date}&end_date=${end_date}&payment_only=true`,
      method: "GET",
    });

    return response.data;
  });

export const useGetSpecificInfluencerPaymentsList = ({
  id,
  start_date = "",
  end_date = "",
}) =>
  useQuery({
    queryKey: ["specificInfluencerPayments", id, start_date, end_date],
    queryFn: async () => {
      const response = await AuthInstance({
        url: `/influencers/${id}/payments?start_date=${start_date}&end_date=${end_date}`,
        method: "GET",
      });
      return response.data;
    },
    enabled: !!id,
  });

export const useGetAuthorityUsers = ({ code }: { code: string }) =>
  useQuery({
    queryKey: ["authority users", code],
    queryFn: async () => {
      const response = await AuthInstanceWithCredentials({
        url: `/auth/common/authority-users?authority=${code}`,
        method: "GET",
      });
      return response.data;
    },
  });

export const useGetReportAvailableId = () =>
  useMutation({
    mutationFn: async () => {
      const response = await Instance({
        url: `/report/admin/report/available-id`,
        method: "GET",
      });
      return response.data;
    },
  });

export const useGetReportList = (query: string = "") =>
  useQuery({
    queryKey: ["report", query],
    queryFn: async () => {
      const response = await Instance({
        url: `/report/admin/reports?offset=0&limit=1000&q=${query}`,
        method: "GET",
      });
      return response.data;
    },
  });

export const useCeateDraftReport = () =>
  useMutation({
    mutationFn: async ({
      content_id,
      body,
    }: {
      content_id: number;
      body: any;
    }) => {
      const response = await Instance({
        url: `/report/admin/report/draft/${content_id}`,
        method: "POST",
        data: body,
      });
      return response.data;
    },
  });

export const useGetDraftReportList = (query: string = "") =>
  useQuery({
    queryKey: ["report", query],
    queryFn: async () => {
      const response = await Instance({
        url: `/report/admin/reports?offset=0&limit=1000&q=${query}`,
        method: "GET",
      });
      return response.data;
    },
  });

export const useGetDraftReportDetail = () =>
  useMutation({
    mutationFn: async ({ content_id }: { content_id: number }) => {
      const response = await Instance({
        url: `/report/admin/report/draft/${content_id}`,
        method: "GET",
      });
      return response.data;
    },
  });

export const useGetReportDetail = () =>
  useMutation({
    mutationFn: async ({ content_id }: { content_id: number }) => {
      const response = await Instance({
        url: `/report/admin/report/${content_id}`,
        method: "GET",
      });
      return response.data;
    },
  });

export const useUpsertReportHtml = () =>
  useMutation({
    mutationFn: async ({
      content_id,
      data,
    }: {
      content_id: number;
      data: any;
    }) => {
      const response = await Instance({
        url: `/report/admin/report/${content_id}/html`,
        method: "PUT",
        data,
      });
      return response.data;
    },
  });

export const useUpsertDraftReportHtml = () =>
  useMutation({
    mutationFn: async ({ content_id }: { content_id: number }) => {
      const response = await Instance({
        url: `/report/admin/report/draft/${content_id}/html`,
        method: "POST",
        data: {},
      });
      return response.data;
    },
  });

export const useEditDraftReport = () =>
  useMutation({
    mutationFn: async ({
      content_id,
      data,
    }: {
      content_id: number;
      data: any;
    }) => {
      const response = await Instance({
        url: `/report/admin/report/draft/${content_id}`,
        method: "PUT",
        data,
      });
      return response.data;
    },
  });

export const useEditReport = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      content_id,
      data,
    }: {
      content_id: number;
      data: any;
    }) => {
      const response = await Instance({
        url: `/report/admin/report/${content_id}`,
        method: "PUT",
        data,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["report", "popular"],
      });
    },
  });
};

export const useScheduleReport = () =>
  useMutation({
    mutationFn: async ({ content_id }: { content_id: number }) => {
      const response = await Instance({
        url: `/report/admin/report/draft/${content_id}/schedule`,
        method: "POST",
      });
      return response.data;
    },
  });

export const useReScheduleReport = () =>
  useMutation({
    mutationFn: async ({ content_id }: { content_id: number }) => {
      const response = await Instance({
        url: `/report/admin/report/${content_id}/schedule`,
        method: "PUT",
      });
      return response.data;
    },
  });

export const useGenPresignedUrl = () =>
  useMutation({
    mutationFn: async (objectKey: string) => {
      const response = await Instance({
        url: `/report/admin/post-presigned-url`,
        method: "POST",
        data: { objectKey },
      });
      return response.data;
    },
  });

export const useGetSeriesList = (query: string = "") =>
  useQuery({
    queryKey: ["series", query],
    queryFn: async () => {
      const response = await Instance({
        url: `/report/admin/series?offset=0&limit=1000&q=${query}`,
        method: "GET",
      });
      return response.data;
    },
  });

export const useGetSeriesDetail = (seriesId: number) =>
  useQuery({
    queryKey: ["series", seriesId],
    queryFn: async () => {
      const response = await Instance({
        url: `/report/admin/series/${seriesId}`,
        method: "GET",
      });
      return response.data;
    },
  });

export const useGetSeriesAvailableReports = (series_id: number) =>
  useQuery({
    queryKey: ["series", "available_reports", series_id],
    queryFn: async () => {
      const response = await Instance({
        url: `/report/admin/series/${series_id}/available_reports`,
        method: "GET",
      });
      return response.data;
    },
  });

export const useCreateSeries = () =>
  useMutation({
    mutationFn: async ({ data }: { data: any }) => {
      const response = await Instance({
        url: `/report/admin/series`,
        method: "POST",
        data,
      });
      return response.data;
    },
  });

export const useEditSeries = () =>
  useMutation({
    mutationFn: async ({
      series_id,
      data,
    }: {
      series_id: number;
      data: any;
    }) => {
      const response = await Instance({
        url: `/report/admin/series/${series_id}`,
        method: "PUT",
        data,
      });
      return response.data;
    },
  });

export const useEditSeriesReportList = () =>
  useMutation({
    mutationFn: async ({
      series_id,
      data,
    }: {
      series_id: number;
      data: any;
    }) => {
      const response = await Instance({
        url: `/report/admin/series/${series_id}/reports`,
        method: "PUT",
        data,
      });
      return response.data;
    },
  });

export const useDeleteSeries = () =>
  useMutation({
    mutationFn: async ({ series_id }: { series_id: number }) => {
      const response = await Instance({
        url: `/report/admin/series/${series_id}`,
        method: "DELETE",
      });
      return response.data;
    },
  });

export const useGetPopularReportList = ({ status }) =>
  useQuery({
    queryKey: ["report", "popular", status],
    queryFn: async () => {
      const response = await Instance({
        url: `/report/admin/latest`,
        method: "POST",
        data: { filter: { status } },
      });
      return response.data;
    },
  });

export const useDeleteReport = () =>
  useMutation({
    mutationFn: async ({ contents_id }: { contents_id: number }) => {
      const response = await Instance({
        url: `/report/admin/report/${contents_id}`,
        method: "DELETE",
      });
      return response.data;
    },
  });
