import { useRef } from "react";
import styled from "styled-components";
import { Close32 } from "@components/core/icon/close";

export default function AddImage({ updateImg, fileName, deleteImg }) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const openFileDialog = () => {
    inputRef.current?.click();
  };

  return (
    <Container>
      <input
        id="file"
        type="file"
        onChange={updateImg}
        ref={inputRef}
        style={{ display: "none" }}
      />
      {fileName ? (
        <FileContaienr>
          <FileName onClick={openFileDialog}>{fileName}</FileName>
          <div onClick={deleteImg} style={{ cursor: "pointer" }}>
            <Close32 />
          </div>
        </FileContaienr>
      ) : (
        <UploadButton onClick={openFileDialog}>
          <span style={{ color: fileName ? "#333e4d" : "#808690" }}>
            썸네일을 등록해주세요
          </span>
        </UploadButton>
      )}
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  height: 50px;
`;

const UploadButton = styled.button`
  display: flex;
  box-sizing: border-box;
  padding: 12px 16px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: #f4f6f8;

  span {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;

const FileContaienr = styled.div`
  height: 50px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FileName = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.blueGray800};

  &:hover {
    cursor: pointer;
  }
`;
