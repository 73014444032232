import moment from "moment";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import styled from "styled-components";

export default function SelectDate({ date, setDate }) {
  return (
    <div className="body" style={{ width: "100%" }}>
      <DateInputWrapper>
        <DatePicker
          selected={date ? new Date(date) : null}
          onChange={(v) => {
            setDate(v);
          }}
          dateFormat="yyyy.MM.dd"
          timeFormat="HH:mm"
          minDate={new Date()}
          locale={ko}
          showTimeSelect
          timeInputLabel="시간 입력:"
          showTimeInput
          shouldCloseOnSelect={false}
          customInput={
            <InputContainer style={{ marginRight: 10 }}>
              {date ? (
                <div className="value">
                  {moment(date).format("YYYY-MM-DD HH:mm")}
                </div>
              ) : (
                <div className="placeholder">날짜 선택</div>
              )}
            </InputContainer>
          }
        />
      </DateInputWrapper>
    </div>
  );
}

const DateInputWrapper = styled.div`
  .react-datepicker-wrapper {
    display: flex;
    flex: 1;
    cursor: pointer;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme }) => theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  .placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }

  .value {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;
