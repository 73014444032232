export default function PlusIcon({ color = "#1C84FF" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <rect x="6" y="15" width="20" height="2" fill={color} />
      <rect
        x="17"
        y="6"
        width="20"
        height="2"
        transform="rotate(90 17 6)"
        fill={color}
      />
    </svg>
  );
}
