import styled from "styled-components";
import ModalList from "./ModalList/ModalList";
import ButtonRow from "./ButtonRow/ButtonRow";
import { useEffect, useState } from "react";
import { Modal } from "@api/management/modal/modal.interfaces";
import { useGetModalList } from "@api/management/modal";

export default function MainModalPage() {
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState<Modal[]>([]);

  const { data: listData } = useGetModalList();

  useEffect(() => {
    if (listData?.modals) {
      setData(listData.modals);
    }
  }, [listData?.modals]);

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const resetData = () => {
    setData(listData.modals);
    handleEditMode();
  };

  return (
    <Container>
      <h1>메인 모달 관리</h1>
      <ButtonRow
        handleEditMode={handleEditMode}
        editMode={editMode}
        data={data}
        resetData={resetData}
      />
      <ModalList editMode={editMode} setData={setData} data={data} />
    </Container>
  );
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 954px;

  h1 {
    color: ${({ theme }) => theme.colors.blueGray900};
    font-size: 32px;
    font-weight: 600;
    line-height: 42px; /* 131.25% */
    letter-spacing: -0.64px;
  }

  h2 {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 18px;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.36px;
  }

  .sub {
    color: ${({ theme }) => theme.colors.blueGray400};
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
  }
`;
