import { useEffect, useCallback } from "react";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import styled from "styled-components";
import { Close32 } from "@components/core/icon/close";

const ReportDragNDropList = ({ items, setItems }) => {
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 250, tolerance: 5 },
    })
  );

  const moveItem = useCallback((activeIndex, overIndex) => {
    setItems((prevItems) => arrayMove(prevItems, activeIndex, overIndex));
  }, []);

  const removeItem = useCallback(
    (id) => {
      setItems((prevItems) => prevItems.filter((item) => item.id !== id));
    },
    [setItems]
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragEnd={({ active, over }) => {
        if (active.id !== over?.id) {
          const activeIndex = items.findIndex((item) => item.id === active.id);
          const overIndex = items.findIndex((item) => item.id === over?.id);
          moveItem(activeIndex, overIndex);
        }
      }}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <ListContainer>
          {items.map((item, index) => (
            <SortableItem
              key={item.id}
              item={item}
              index={index}
              onRemove={() => removeItem(item.id)}
            />
          ))}
        </ListContainer>
      </SortableContext>
    </DndContext>
  );
};

const SortableItem = ({ item, index, onRemove }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    zIndex: isDragging ? 1 : "auto",
  };

  return (
    <Item ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ItemLeft>
        <DragHandle>☰</DragHandle>
        {item.label}
      </ItemLeft>
      <div onClick={onRemove}>
        <Close32 />
      </div>
    </Item>
  );
};

// Styled Components
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin: 20px auto;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: grab;

  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #666e7a;
`;

const ItemLeft = styled.div`
  display: flex;
  align-items: center;
`;

const DragHandle = (props) => (
  <div style={{ marginRight: 12, cursor: "grap" }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <circle cx="2" cy="2" r="2" fill="#999FA6"></circle>
      <circle cx="2" cy="10" r="2" fill="#999FA6"></circle>
      <circle cx="10" cy="2" r="2" fill="#999FA6"></circle>
      <circle cx="10" cy="10" r="2" fill="#999FA6"></circle>
    </svg>
  </div>
);

export default ReportDragNDropList;
