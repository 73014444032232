import { useEffect, useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getCoreRowModel } from "@tanstack/react-table";
import styled from "styled-components";
import cn from "classnames";
import Pagination from "@components/core/paging/Pagination";
import "@components/table.css";
import { convertToKoreanTime } from "@hooks/calc/converToKoreanDate";
import Empty from "@components/common/Empty";
import { useMenu } from "@features/useMenu";
import TableSkeleton from "@components/skeleton/TableSkeleton";

export default function ReportListTable({ data, isLoading }) {
  const { globalThemeMode } = useMenu();

  const getStatus = (startDate, endDate) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : null;

    if (currentDate < start) {
      return "대기"; // 현재 시간보다 시작 시간이 이전이면 '대기'
    } else if (end && currentDate > end) {
      return "만료"; // 끝 시간이 있고 현재 시간이 끝 시간보다 크면 '만료'
    } else {
      return "진행"; // 그 외에는 '진행'
    }
  };

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("content_id", {
        cell: ({ getValue }) => getValue(),
        header: "ID",
        maxSize: 15,
      }),
      columnHelper.accessor("title", {
        cell: ({ row }) => {
          return <Title>{row.original["title"]}</Title>;
        },
        header: "제목",
        maxSize: 35,
      }),
      columnHelper.accessor(
        (row) =>
          `${row["new_report_start_date"]} ${row["new_report_end_date"]}`,
        {
          cell: ({ row }) => {
            return `${convertToKoreanTime(
              row.original["new_report_start_date"]
            )} ~ ${
              convertToKoreanTime(row.original["new_report_end_date"]) === "-"
                ? ""
                : convertToKoreanTime(row.original["new_report_end_date"])
            }`;
          },
          header: "등록 기간",
          maxSize: 35,
        }
      ),
      columnHelper.accessor(
        (row) =>
          `${row["new_report_start_date"]} ${row["new_report_end_date"]}`,
        {
          cell: ({ row }) => {
            const status = getStatus(
              row.original["new_report_start_date"],
              row.original["new_report_end_date"]
            );
            return status;
          },
          header: "상태",
          maxSize: 15,
        }
      ),
    ];
  }, [data, isLoading]);

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(10);
  }, []);

  return (
    <Container>
      <table className="contentsTable">
        <thead
          className={cn("contentsThead", {
            darkContentsThead: globalThemeMode === "dark",
          })}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={cn("contentsHeader", {
                    darkContentsHeader: globalThemeMode === "dark",
                  })}
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={cn("contentsData", {
                    darkContentsData: globalThemeMode === "dark",
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Empty data={data} title="컨텐츠가 없어요" />
      )}
      <Pagination table={table} />
    </Container>
  );
}

const Container = styled.div`
  margin-top: 16px;
`;

const Title = styled.h2`
  margin-left: 20px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
`;
