export const findSelectedOption = (list, value) => {
  for (const group of list) {
    const selectedOption = group.contents.find(
      (content) => content.value === value
    );
    if (selectedOption) {
      return selectedOption;
    }
  }
  return false;
};
