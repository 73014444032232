import { useEditModalIndex } from "@api/management/modal";
import { Modal } from "@api/management/modal/modal.interfaces";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import styled from "styled-components";

export default function ButtonRow({
  handleEditMode,
  editMode,
  data,
  resetData,
}: {
  handleEditMode: () => void;
  editMode: boolean;
  data: Modal[];
  resetData: () => void;
}) {
  const { mutate } = useEditModalIndex();
  const changeIndex = () => {
    mutate(
      { order: data.map((modal) => modal.id) },
      {
        onSuccess: () => {
          alert("순서 변경 완료");
        },
        onError: (error) => {
          alert(`순서 변경에 실패했습니다.\n${error.response.data}`);
        },
        onSettled: () => {
          handleEditMode();
        },
      }
    );
  };

  return (
    <Container>
      {editMode ? (
        <LeftSide>
          <button onClick={resetData}>
            <span>취소</span>
          </button>
          <button className="blue" onClick={changeIndex}>
            <span>변경완료</span>
          </button>
        </LeftSide>
      ) : (
        <LeftSide>
          <a href="modal/add">
            <AddIcon />
            <span>모달 추가하기</span>
          </a>
          <button onClick={handleEditMode}>
            <ChangeIndex />
            <span>순서 변경</span>
          </button>
        </LeftSide>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 12px;

  a,
  button {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 7px 16px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.blueGray100};
    background: ${({ theme }) => theme.colors.white};

    span {
      color: ${({ theme }) => theme.colors.blueGray700};
      font-size: 15px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.3px;
    }
  }
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .blue {
    background: ${({ theme }) => theme.colors.primaryBlue100};
    border: none;
    span {
      color: white;
    }
  }
`;

const AddIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M3.4375 2.75H10.3125V19.25H3.4375C3.05937 19.25 2.75 18.9406 2.75 18.5625V3.4375C2.75 3.05937 3.05937 2.75 3.4375 2.75Z"
        fill="#4D5664"
      />
      <path
        d="M9.625 3.4375H3.4375V18.5625H9.625V3.4375Z"
        stroke="#4D5664"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M11.6875 2.75H18.5625C18.9406 2.75 19.25 3.05937 19.25 3.4375V10.3125H11.6875V2.75Z"
        fill="#4D5664"
      />
      <path
        d="M18.5625 3.4375H12.375V9.625H18.5625V3.4375Z"
        stroke="#4D5664"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M11.6875 11.6875H19.25V18.5625C19.25 18.9406 18.9406 19.25 18.5625 19.25H11.6875V11.6875Z"
        fill="#4D5664"
      />
      <path
        d="M18.5625 12.375H12.375V18.5625H18.5625V12.375Z"
        stroke="#4D5664"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const ChangeIndex = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M15.4141 9.625H12.3203V6.53125C12.3203 4.82625 13.7091 3.4375 15.4141 3.4375C17.1191 3.4375 18.5078 4.82625 18.5078 6.53125C18.5078 8.23625 17.1191 9.625 15.4141 9.625Z"
        fill="#4D5664"
      />
      <path
        d="M15.4141 8.9375C16.7409 8.9375 17.8203 7.85812 17.8203 6.53125C17.8203 5.20438 16.7409 4.125 15.4141 4.125C14.0872 4.125 13.0078 5.20438 13.0078 6.53125V8.9375H15.4141ZM15.4141 10.3125H11.6328V6.53125C11.6328 4.44125 13.3241 2.75 15.4141 2.75C17.5041 2.75 19.1953 4.44125 19.1953 6.53125C19.1953 8.62125 17.5041 10.3125 15.4141 10.3125Z"
        fill="#4D5664"
      />
      <path
        d="M9.63281 9.625H6.53906C4.83406 9.625 3.44531 8.23625 3.44531 6.53125C3.44531 4.82625 4.83406 3.4375 6.53906 3.4375C8.24406 3.4375 9.63281 4.82625 9.63281 6.53125V9.625Z"
        fill="#4D5664"
      />
      <path
        d="M8.94531 8.9375V6.53125C8.94531 5.20438 7.86594 4.125 6.53906 4.125C5.21219 4.125 4.13281 5.20438 4.13281 6.53125C4.13281 7.85812 5.21219 8.9375 6.53906 8.9375H8.94531ZM10.3203 10.3125H6.53906C4.44906 10.3125 2.75781 8.62125 2.75781 6.53125C2.75781 4.44125 4.44906 2.75 6.53906 2.75C8.62906 2.75 10.3203 4.44125 10.3203 6.53125V10.3125Z"
        fill="#4D5664"
      />
      <path
        d="M15.4141 18.5625C13.7091 18.5625 12.3203 17.1737 12.3203 15.4688V12.375H15.4141C17.1191 12.375 18.5078 13.7637 18.5078 15.4688C18.5078 17.1737 17.1191 18.5625 15.4141 18.5625Z"
        fill="#4D5664"
      />
      <path
        d="M15.4141 17.875C16.7409 17.875 17.8203 16.7956 17.8203 15.4688C17.8203 14.1419 16.7409 13.0625 15.4141 13.0625H13.0078V15.4688C13.0078 16.7956 14.0872 17.875 15.4141 17.875ZM15.4141 19.25C13.3241 19.25 11.6328 17.5588 11.6328 15.4688V11.6875H15.4141C17.5041 11.6875 19.1953 13.3788 19.1953 15.4688C19.1953 17.5588 17.5041 19.25 15.4141 19.25Z"
        fill="#4D5664"
      />
      <path
        d="M6.53906 18.5625C4.83406 18.5625 3.44531 17.1737 3.44531 15.4688C3.44531 13.7637 4.83406 12.375 6.53906 12.375H9.63281V15.4688C9.63281 17.1737 8.24406 18.5625 6.53906 18.5625Z"
        fill="#4D5664"
      />
      <path
        d="M6.53906 17.875C7.86594 17.875 8.94531 16.7956 8.94531 15.4688V13.0625H6.53906C5.21219 13.0625 4.13281 14.1419 4.13281 15.4688C4.13281 16.7956 5.21219 17.875 6.53906 17.875ZM6.53906 19.25C4.44906 19.25 2.75781 17.5588 2.75781 15.4688C2.75781 13.3788 4.44906 11.6875 6.53906 11.6875H10.3203V15.4688C10.3203 17.5588 8.62906 19.25 6.53906 19.25Z"
        fill="#4D5664"
      />
    </svg>
  );
};
