import { useState } from "react";
import styled from "styled-components";
import Section from "../Section";
import { Arrows22 } from "@components/core/icon/arrow";
import {
  ArrowDirectionEnum,
  ArrowColorEnum,
} from "@components/core/icon/arrow/Arrow.interfaces";
import CircleCheckRow from "@components/core/list/CircleCheckRow";

const TAG_LIST = [
  "부동산 상식",
  "추천아파트",
  "정책분석",
  "시장분석",
  "지역분석",
];

const PRICE_LIST = [
  { label: "유료", value: "paid" },
  { label: "무료", value: "free" },
];

export default function FilterPrice({ reportInfo, setReportInfo }) {
  const [isShown, setIsShown] = useState(true);

  const handleTag = (tag: string) => {
    const current = [...reportInfo.filter_tags];

    if (current.includes(tag)) {
      const filtered = current.filter((v) => v !== tag);
      setReportInfo((prev) => ({ ...prev, filter_tags: filtered }));
    } else {
      setReportInfo((prev) => ({ ...prev, filter_tags: [...current, tag] }));
    }
  };

  const handlePrice = (paymentType: string) => {
    const current = reportInfo.payment_type;
    let prevFilterTags = [...reportInfo.filter_tags];
    prevFilterTags = prevFilterTags.filter(
      (tag) => tag !== "무료" && tag !== "유료"
    );

    if (paymentType === current) {
      setReportInfo((prev) => ({
        ...prev,
        payment_type: null,
        coin_amount: null,
        filter_tags: prevFilterTags,
      }));
    } else {
      if (paymentType === "free") {
        prevFilterTags.push("무료");
      } else if (paymentType === "paid") {
        prevFilterTags.push("유료");
      }

      setReportInfo((prev) => ({
        ...prev,
        payment_type: paymentType,
        coin_amount: paymentType === "free" ? 0 : 3,
        filter_tags: prevFilterTags,
      }));
    }
  };

  return (
    <Container>
      <TitleBox onClick={() => setIsShown(!isShown)}>
        <div style={{ display: "flex" }}>
          <h3>필터 및 가격 설정</h3>
          <Star />
        </div>
        <Arrows22
          direction={isShown ? ArrowDirectionEnum.UP : ArrowDirectionEnum.DOWN}
          color={ArrowColorEnum.B50}
        />
      </TitleBox>
      {isShown && (
        <ContentContainer>
          <Section
            title="태그"
            bodyComponent={
              <ItemListContainer>
                {TAG_LIST.map((v) => {
                  const active = reportInfo.filter_tags.includes(v);
                  return (
                    <CircleCheckRow
                      key={v}
                      text={v}
                      active={active}
                      onClick={() => handleTag(v)}
                      containerStyle={{ width: "auto", marginRight: "8px" }}
                    />
                  );
                })}
              </ItemListContainer>
            }
          />
          <Section
            title="가격 설정"
            bodyComponent={
              <ItemListContainer>
                {PRICE_LIST.map((v) => {
                  const active = reportInfo.payment_type === v.value;

                  return (
                    <CircleCheckRow
                      key={v.label}
                      text={v.label}
                      active={active}
                      onClick={() => handlePrice(v.value)}
                      containerStyle={{ width: "auto", marginRight: "8px" }}
                    />
                  );
                })}
              </ItemListContainer>
            }
          />
        </ContentContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 10px;
  border: 1px solid #e5e7e9;
  border-radius: 16px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  height: 64px;
  padding: 19px 16px;

  h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #333e4d;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Star = styled.p`
  margin: 4px 0 0 2px;

  &::after {
    color: #f04452;
    content: "*";
  }
`;

const ContentContainer = styled.div`
  border-top: 1px solid #e5e7e9;
  padding-bottom: 8px;
`;

const ItemListContainer = styled.ul`
  display: flex;
  align-items: center;
`;
