import { useEffect, useMemo, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getCoreRowModel } from "@tanstack/react-table";
import styled from "styled-components";
import "@components/table.css";
import { convertToKoreanDate } from "@hooks/calc/converToKoreanDate";
import Empty from "@components/common/Empty";
import { Link, useNavigate } from "react-router-dom";
import TableSkeleton from "@components/skeleton/TableSkeleton";
import ManualPagination from "@components/core/paging/ManualPagination";
import useCustomSearchParams from "@hooks/useCustomSearchParams";

export default function QnATable({ data, total, isLoading }) {
  const 페이지당_아이템개수 = 10;
  const { searchParams } = useCustomSearchParams();
  const page = Number(searchParams.page || 1);
  const navigate = useNavigate();

  const columnHelper = createColumnHelper();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("number", {
        cell: (info) => {
          return total - (페이지당_아이템개수 * (page - 1) + info.row.index);
        },
        header: "번호",
        maxSize: 7,
      }),
      columnHelper.accessor("question", {
        cell: ({ row }) => {
          return (
            <Title onClick={() => navigate(`${row.original["id"]}`)}>
              {row.original["question"]}
            </Title>
          );
        },
        header: "문의 내용",
        maxSize: 70,
      }),
      columnHelper.accessor("created_at", {
        cell: ({ getValue }) => {
          return convertToKoreanDate(getValue());
        },
        header: "문의 일자",
        maxSize: 10,
      }),
      columnHelper.accessor("answer", {
        cell: ({ getValue }) => {
          return getValue() ? "답변완료" : "답변대기";
        },
        header: "상태",
        maxSize: 10,
      }),
    ];
  }, [data]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex: false, //페이지를 변경하는 상태 변화가 발생할 때 pageIndex는 0으로 리셋되는 옵션
  });

  useEffect(() => {
    table.setPageSize(페이지당_아이템개수);
  }, []);

  return (
    <Container>
      <table className="table">
        <thead className="head">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="contentsHeader"
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="contentsData">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Empty data={data} title="문의 내역이 없어요" />
      )}
      <ManualPagination total={total} itemPerPage={페이지당_아이템개수} />
    </Container>
  );
}

const Container = styled.div`
  .table {
    width: 100%;
  }

  .head {
    background-color: ${({ theme }) => theme.colors.blueGrayA100};
  }
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.primaryBlue100};
`;

const EditContents = styled.p`
  color: ${({ theme }) => theme.colors.primaryBlue100};
  cursor: pointer;
`;

const Title = styled.h2`
  margin-left: 20px;
  text-align: left;
  max-width: 660px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;
