import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useGetReportDetail, useUpsertReportHtml } from "@hooks/admin";

export default function PreviewReport() {
  const params = useParams();
  const { data, mutate: 레포트상세조회 } = useGetReportDetail();
  const { mutate: html재생성 } = useUpsertReportHtml();
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    if (params.id) {
      레포트상세조회({ content_id: Number(params.id) });
    }
  }, [params]);

  const 다시생성 = () => {
    setButtonLoading(true);

    html재생성(
      { content_id: Number(params.id), data: {} },
      {
        onSuccess() {
          let attempt = 0;
          const intervalId = setInterval(() => {
            attempt++;
            레포트상세조회(
              { content_id: Number(params.id) },
              {
                onSuccess: (detail) => {
                  if (detail.html) {
                    // 특정 값 체크
                    clearInterval(intervalId); // 성공 시 Interval 제거
                    alert(
                      " 생성 완료\n팝업이 차단된 경우 생성된 html을 확인할 수 없으며, 팝업 허용 후 추가 레포트를 다시 등록해주세요레포트 값 생성 확인"
                    );
                    setButtonLoading(false);
                    // window.location.reload();
                  }
                },
                onError: () => {
                  alert("레포트 상세 조회 실패");
                  clearInterval(intervalId);
                  setButtonLoading(false);
                },
              }
            );

            if (attempt >= 30) {
              clearInterval(intervalId); // Interval 제거
              alert("최대 대기 시간 초과. 특정 값 생성 안 됨.");
              setButtonLoading(false);
            }
          }, 2000); // 2초마다 실행
        },
        onError() {
          alert("html 재생성에 실패했습니다.\n개발팀에 문의하세요.");
          setButtonLoading(false);
        },
      }
    );
  };

  const 확인 = () => {
    window.close();
  };

  return (
    <Container>
      {data?.html && (
        <HtmlContainer dangerouslySetInnerHTML={{ __html: data.html }} />
      )}
      <ButtonContainer>
        <RegenButton disabled={buttonLoading} onClick={다시생성}>
          {buttonLoading ? "로딩" : "다시 생성"}
        </RegenButton>
        <ConfirmButton disabled={buttonLoading} onClick={확인}>
          {buttonLoading ? "로딩" : "확인"}
        </ConfirmButton>
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.div``;

const HtmlContainer = styled.section``;

const ButtonContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px 120px;
  display: flex;
  background-color: #fff;
`;

const RegenButton = styled.button`
  flex: 1;
  height: 56px;
  border-radius: 16px;
  background-color: #e3f0ff;
  color: #1c84ff;
  font-size: 18px;
`;

const ConfirmButton = styled.button`
  margin-left: 8px;
  flex: 2;
  height: 56px;
  border-radius: 16px;
  background-color: #1c84ff;
  color: #fff;
  font-size: 18px;
`;
