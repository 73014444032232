import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import {
  useEditDraftReport,
  useScheduleReport,
  useGenPresignedUrl,
} from "@hooks/admin";
import { uploadReportThumbnailImageToS3 } from "@utils/file";

export default function ConfirmButton({
  contentId,
  reportInfo,
  additional,
  isFavoriteActive,
}) {
  const navigate = useNavigate();
  const { mutate: 레포트초안수정 } = useEditDraftReport();
  const { mutate: 레포트발행예약 } = useScheduleReport();
  const { mutateAsync: genPresignedUrl } = useGenPresignedUrl();
  const [buttonLoading, setButtonLoading] = useState(false);

  const isDone = useMemo(() => {
    const allPushFields = [
      reportInfo.push_target,
      reportInfo.push_title,
      reportInfo.push_body,
      reportInfo.push_schedule,
    ];

    const areAllPushFieldsConsistent =
      allPushFields.every(Boolean) || allPushFields.every((field) => !field);

    const isFavoriteDatesValid = !isFavoriteActive
      ? reportInfo.new_report_start_date === null &&
        reportInfo.new_report_end_date === null
      : reportInfo.new_report_start_date && reportInfo.new_report_end_date;

    return (
      reportInfo.influencer &&
      reportInfo.new_reports_desc &&
      (additional === "none" ||
        (additional === "consulting_id" && reportInfo.consulting_id) ||
        (additional === "danji_list" && reportInfo.danji_list?.length > 0)) &&
      reportInfo.kakao_shared_thumbnail.file &&
      reportInfo.thumbnail_80.file &&
      reportInfo.thumbnail_120.file &&
      reportInfo.thumbnail_327.file &&
      reportInfo.filter_tags.length > 0 &&
      reportInfo.payment_type &&
      reportInfo.coin_amount !== null &&
      areAllPushFieldsConsistent &&
      isFavoriteDatesValid
    );
  }, [reportInfo, additional, isFavoriteActive]);

  const 등록 = () => {
    setButtonLoading(true);
    const isoString = new Date().toISOString();

    const imageFields = [
      "kakao_shared_thumbnail",
      "thumbnail_80",
      "thumbnail_120",
      "thumbnail_327",
    ];

    const uploadedImages = {};

    const promises = imageFields.map(async (field) => {
      const objectKey = `contents_id_${contentId}/${field}_${isoString}.png`;
      const res = await genPresignedUrl(objectKey);
      await uploadReportThumbnailImageToS3({
        presignedObj: res.presigned_url,
        file: reportInfo[field].file,
      });
      uploadedImages[field] = `${res.presigned_url.url.replace(
        "s3",
        "s3.ap-northeast-2"
      )}${objectKey}`;
    });

    Promise.all(promises)
      .then(() => {
        레포트초안수정(
          { content_id: contentId, data: { ...reportInfo, ...uploadedImages } },
          {
            onSuccess() {
              레포트발행예약(
                { content_id: contentId },
                {
                  onSuccess() {
                    alert("등록 완료!");
                    setButtonLoading(false);
                    navigate(-1);
                  },
                  onError() {
                    alert("레포트 발행 예약에 실패했습니다.");
                    setButtonLoading(false);
                  },
                }
              );
            },
            onError() {
              alert("레포트 수정 실패");
              setButtonLoading(false);
            },
          }
        );
      })
      .catch((error) => {
        alert("이미지 업로드 실패");
        setButtonLoading(false);
      });
  };

  return (
    <ButtonContainer>
      <Container onClick={등록} isDone={isDone} disabled={!isDone}>
        {buttonLoading ? "로딩중" : "등록"}
      </Container>
      {buttonLoading && <AbsoluteFillLoading />}
    </ButtonContainer>
  );
}

const Container = styled.button<{ isDone: boolean }>`
  width: 140px;
  height: 48px;
  border-radius: 8px;

  background-color: ${({ isDone }) => (isDone ? "#1C84FF" : "#e5e7e9")};

  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  color: ${({ isDone }) => (isDone ? "#fff" : "#808690")};
`;

const ButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
