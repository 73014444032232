import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BTypeText48, TextInputEnum } from "@components/input/BTypeText48";
import BTypeTextArea from "@components/core/BTypeInput/BTypeTextArea";
import Section from "../Section";
import ReportDragNDropList from "./ReportDragNDropList";

import { useEditSeries } from "@hooks/admin";

export default function SeriesDetail({
  seriesId,
  seriesInfo,
  setSeriesInfo,
  newReportList,
  setNewReportList,
}) {
  const navigate = useNavigate();
  const { mutate: 시리즈수정 } = useEditSeries();

  const 취소 = () => navigate(-1);
  const 적용 = () => {
    시리즈수정(
      {
        series_id: seriesId,
        data: {
          title: seriesInfo.title,
          desc: seriesInfo.desc,
          contentsIdList: newReportList.map((v) => v.id),
        },
      },
      {
        onSuccess() {
          alert("성공");
          navigate(-1);
        },
        onError() {
          alert("실패");
        },
      }
    );
  };

  const 컨텐츠검색열기 = () => {
    const left = window.screenX + 100; // 현재 창의 X 좌표 기준
    const top = window.screenY + 100; // 현재 창의 Y 좌표 기준

    window.open(
      `/search/series/${seriesId}/report`,
      "popupWindow", // 창 이름
      `width=${490},height=${600},left=${left},top=${top},scrollbars=no` // 창 크기 및 옵션 설정
    );
  };

  useEffect(() => {
    const handleStorage = (event) => {
      if (event.key === "series_new_report") {
        const currentDanjiList = [...newReportList];
        const newReport = JSON.parse(event.newValue);

        setNewReportList([...currentDanjiList, newReport]);
      }
    };

    window.addEventListener("storage", handleStorage);

    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, [newReportList]);

  return (
    <Container>
      <Section
        title="시리즈 제목"
        showStar={true}
        bodyComponent={
          <BTypeText48
            placeholder="제목을 입력해 주세요"
            textType={TextInputEnum.DEFAULT}
            value={seriesInfo.title}
            onChangeValue={(value) =>
              setSeriesInfo((prev) => ({ ...prev, title: value }))
            }
            containerStyle={{ height: "auto" }}
          />
        }
      />
      <Section
        title="시리즈 소개"
        showStar={true}
        bodyComponent={
          <BTypeTextArea
            placeholder="제목을 입력해 주세요"
            value={seriesInfo.desc}
            onChangeValue={(value) =>
              setSeriesInfo((prev) => ({ ...prev, desc: value }))
            }
            textareaStyle={{ height: 130, fontSize: 15 }}
          />
        }
      />
      <Separator />
      <Section
        title="컨텐츠 관리"
        bodyComponent={
          <ReportListContaienr>
            <CreateButton onClick={컨텐츠검색열기}>
              <p>컨텐츠 추가하기</p>
            </CreateButton>
            <ReportDragNDropList
              items={newReportList}
              setItems={setNewReportList}
            />
          </ReportListContaienr>
        }
      />
      <Separator />
      <ButtonContainer>
        <button onClick={취소} className="cancel">
          취소
        </button>
        <button onClick={적용} className="confirm">
          적용
        </button>
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 16px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: #e5e7e9;
`;

const ReportListContaienr = styled.div``;

const CreateButton = styled.div`
  padding: 9px 16px;
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.blueGrayA700};
  cursor: pointer;

  p {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
    color: white;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  button {
    width: 80px;
    height: 40px;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: center;
    border-radius: 8px;
  }

  .cancel {
    color: #4d5664;
    background-color: #fff;
    border: 1px solid #e5e7e9;
  }

  .confirm {
    color: #fff;
    background-color: #1c84ff;
  }
`;
