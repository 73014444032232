import { useRef } from "react";
import styled from "styled-components";

export default function AddImage({ updateImg, fileName }) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const openFileDialog = () => {
    inputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      updateImg(file, file.name);
    }
  };

  return (
    <Container>
      <input
        id="file"
        type="file"
        onChange={handleFileChange}
        ref={inputRef}
        style={{ display: "none" }}
      />
      <UploadButton onClick={openFileDialog}>
        <span style={{ color: fileName ? "#333e4d" : "#808690" }}>
          {fileName ? fileName : "이미지 등록"}
        </span>
      </UploadButton>
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
`;

const UploadButton = styled.button`
  display: flex;
  box-sizing: border-box;
  padding: 12px 16px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: #f4f6f8;

  span {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;
