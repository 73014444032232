import { useState } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import moment from "moment";
import styled from "styled-components";

import Section from "../Section";
import SwitchButton from "@components/button/SwitchButton";
import { Arrows22 } from "@components/core/icon/arrow";
import {
  ArrowDirectionEnum,
  ArrowColorEnum,
} from "@components/core/icon/arrow/Arrow.interfaces";

export default function FavoriteReport({
  reportInfo,
  setReportInfo,
  isFavoriteActive,
  setIsFavoriteActive,
}) {
  const [isShown, setIsShown] = useState(false);

  const handleToggle = () => {
    if (isFavoriteActive) {
      setReportInfo((prev) => ({
        ...prev,
        new_report_start_date: null,
        new_report_end_date: null,
      }));
    }
    setIsFavoriteActive((prev) => !prev);
  };

  return (
    <Container>
      <TitleBox onClick={() => setIsShown(!isShown)}>
        <div style={{ display: "flex" }}>
          <h3>인기 레포트 설정</h3>
        </div>
        <Arrows22
          direction={isShown ? ArrowDirectionEnum.UP : ArrowDirectionEnum.DOWN}
          color={ArrowColorEnum.B50}
        />
      </TitleBox>
      {isShown && (
        <ContentContainer>
          <Section
            title="인기 레포트 등록"
            bodyComponent={
              <SwitchContainer>
                <SwitchButton
                  active={isFavoriteActive}
                  handleToggle={handleToggle}
                />
              </SwitchContainer>
            }
          />
          <Section
            title="등록 기간 설정"
            showStar={true}
            bodyComponent={
              <RangeContainer>
                <PickerContainer>
                  <DatePicker
                    selected={
                      reportInfo.new_report_start_date
                        ? new Date(reportInfo.new_report_start_date)
                        : null
                    }
                    onChange={(date: Date) => {
                      if (date) {
                        const selectedDate = new Date(date);
                        const dayOfWeek = selectedDate.getDay(); // 0: Sunday, 1: Monday, ..., 6: Saturday

                        // 평일은 07:00, 주말은 11:00로 설정
                        selectedDate.setHours(
                          dayOfWeek === 0 || dayOfWeek === 6 ? 11 : 7,
                          0,
                          0,
                          0
                        );

                        setReportInfo((prev) => ({
                          ...prev,
                          new_report_start_date: selectedDate.toISOString(),
                        }));
                      }
                    }}
                    dateFormat="yyyy.MM.dd"
                    minDate={new Date()}
                    locale={ko}
                    timeFormat="HH:mm"
                    shouldCloseOnSelect
                    onCalendarClose={() => {}}
                    disabled={!isFavoriteActive}
                    customInput={
                      <DateInputContainer disabled={!isFavoriteActive}>
                        {reportInfo.new_report_start_date ? (
                          <div className="value">
                            {moment(reportInfo.new_report_start_date).format(
                              "YYYY-MM-DD HH:mm"
                            )}
                          </div>
                        ) : (
                          <div className="placeholder">시작 일시</div>
                        )}
                      </DateInputContainer>
                    }
                  />
                  <div style={{ margin: "0 8px" }}>~</div>
                  <DatePicker
                    selected={
                      reportInfo.new_report_end_date
                        ? new Date(reportInfo.new_report_end_date)
                        : null
                    }
                    onChange={(date: Date) => {
                      if (date) {
                        const selectedDate = new Date(date);

                        // 평일은 07:00, 주말은 11:00로 설정
                        selectedDate.setHours(23, 55, 0, 0);

                        setReportInfo((prev) => ({
                          ...prev,
                          new_report_end_date: selectedDate.toISOString(),
                        }));
                      }
                    }}
                    dateFormat="yyyy.MM.dd"
                    minDate={new Date()}
                    locale={ko}
                    timeFormat="HH:mm"
                    shouldCloseOnSelect
                    onCalendarClose={() => {}}
                    disabled={!isFavoriteActive}
                    customInput={
                      <DateInputContainer disabled={!isFavoriteActive}>
                        {reportInfo.new_report_end_date ? (
                          <div className="value">
                            {moment(reportInfo.new_report_end_date).format(
                              "YYYY-MM-DD HH:mm"
                            )}
                          </div>
                        ) : (
                          <div className="placeholder">종료 일시</div>
                        )}
                      </DateInputContainer>
                    }
                  />
                </PickerContainer>
                <DateMessage>
                  인기 레포트 순서는 시작 일이 빠른 순서대로 보여집니다. 임의로
                  순서를 부여하고 싶다면 인기레포트 등록 관리에서 설정할 수
                  있습니다.{"\n"}또한 등록 기간이 지난 경우 인기 레포트 항목에서
                  자동으로 제외됩니다.
                </DateMessage>
              </RangeContainer>
            }
          />
        </ContentContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 10px;
  border: 1px solid #e5e7e9;
  border-radius: 16px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  height: 64px;
  padding: 19px 16px;

  h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #333e4d;
  }

  &:hover {
    cursor: pointer;
  }
`;

const ContentContainer = styled.div`
  border-top: 1px solid #e5e7e9;
  padding-bottom: 8px;
`;

const SwitchContainer = styled.div`
  display: flex;
  height: 48px;
`;

const RangeContainer = styled.div`
  padding-bottom: 20px;
`;

const PickerContainer = styled.div`
  display: flex;
  align-items: center;

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const DateInputContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray100};
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.blueGray100 : theme.colors.blueGrayA100};

  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 18px;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;

  .placeholder {
    color: ${({ theme }) => theme.colors.blueGray500};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }

  .value {
    color: ${({ theme }) => theme.colors.blueGray800};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;

const DateMessage = styled.p`
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #808690;
  white-space: pre-wrap;
`;
