import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  useDeleteModal,
  useEditModal,
  useGetPresignedUrl,
} from "@api/management/modal";
import AbsoluteFillLoading from "@components/common/AbsoluteFillLoading";
import { ButtonBox } from "@components/styles";
import { uploadModalImageToS3 } from "@utils/file";
import { useAlert } from "@features/useAlert";
import { PopUpContainer } from "@components/core/popUp/PopupContainer";
import { useState } from "react";
import styled from "styled-components";
import { getAddressInfo } from "@utils/address";

export default function EditButton({ isDone, modalInfo }) {
  const navigate = useNavigate();
  const { mutate: getPresignedUrl, isLoading: urlLoading } =
    useGetPresignedUrl();
  const { mutate, isLoading } = useEditModal();
  const { mutate: deleteModal } = useDeleteModal();
  const { showGlobalAlert } = useAlert();
  const [isVisble, setIsVisible] = useState(false);
  const handleConfirm = async () => {
    console.log("modal", modalInfo);
    if (modalInfo.startAt > modalInfo.endAt) {
      alert("종료일이 시작일보다 빠릅니다.");
      return;
    }

    if (modalInfo.externalUrl) {
      try {
        new URL(modalInfo.externalUrl);
      } catch (_) {
        alert("유효한 URL이 아닙니다.");
        return;
      }
    }

    if (modalInfo.deeplink) {
      if (
        modalInfo.deeplink.includes("danji/:id") ||
        modalInfo.deeplink.includes("maemul")
      ) {
        const data = await getAddressInfo({
          danjiCode: modalInfo.deeplinkId,
        });

        if (!data) {
          alert("유효한 단지코드가 아닙니다.");
          return;
        }
      }
    }

    const createRequestBody = (imageUrl) => ({
      startAt: moment(modalInfo.startDate).add(9, "hours").toISOString(),
      endAt: moment(modalInfo.endDate).add(9, "hours").toISOString(),
      id: modalInfo.id,
      imageUrl,
      ...(modalInfo.deeplink && {
        deepLink: modalInfo.deeplinkId
          ? modalInfo.deeplink.replace(":id", `{id:${modalInfo.deeplinkId}}`)
          : modalInfo.deeplink,
      }),
      ...(modalInfo.externalUrl && { externalUrl: modalInfo.externalUrl }),
    });

    const handleSave = (requestBody) => {
      mutate(requestBody, {
        onSuccess: () => {
          showGlobalAlert({
            desc: "정상적으로 등록이 되었습니다",
            blue: true,
            path: -1,
          });
        },
        onError: (error) => {
          showGlobalAlert({
            desc: `[${error.response.data["message"]}]\n일시적인 오류로 삭제 실패했습니다.\n다시 시도해주세요.`,
            blue: false,
            path: -1,
          });
        },
      });
    };

    if (modalInfo.file) {
      const fileName = modalInfo.file.name;
      getPresignedUrl(
        { type: fileName.split(".").pop() },
        {
          onSuccess: async (data) => {
            const check = await uploadModalImageToS3({
              presignedObj: data.url,
              file: modalInfo.file,
            });
            if (check) {
              const { url, fields } = data.url;
              const imageUrl = `${url}${fields.key}`;
              handleSave(createRequestBody(imageUrl));
            } else {
              alert("이미지 업로드 url 생성 실패");
            }
          },
        }
      );
    } else {
      handleSave(createRequestBody(modalInfo.image));
    }
  };

  const handleDelete = () => {
    deleteModal(
      { id: modalInfo.id },
      {
        onSuccess: () => {
          alert("삭제 완료");
          navigate(-1);
        },
        onError: () => {
          alert("삭제 실패");
        },
      }
    );
  };

  return (
    <>
      <ButtonBox style={{ marginTop: 20 }}>
        <button
          onClick={() => setIsVisible(true)}
          style={{
            backgroundColor: "#FDECEE",
            color: "#F04452",
            border: "none",
          }}
        >
          삭제
        </button>
        <button
          className={!isDone ? "disabled" : ""}
          disabled={!isDone}
          onClick={handleConfirm}
        >
          적용
        </button>
      </ButtonBox>
      {(isLoading || urlLoading) && <AbsoluteFillLoading />}
      <DeleteAlert
        isVisble={isVisble}
        setIsVisible={setIsVisible}
        handleDelete={handleDelete}
      />
    </>
  );
}

const DeleteAlert = ({
  isVisble,
  setIsVisible,
  handleDelete,
}: {
  isVisble: boolean;
  setIsVisible: (v: boolean) => void;
  handleDelete: () => void;
}) => {
  return (
    <PopUpContainer isVisible={isVisble} setIsVisible={setIsVisible}>
      <Container>
        <Title>등록한 모달정보를 삭제할까요?</Title>
        <Description>아래 사항을 확인하세요</Description>
        <Content>
          <Dot />
          <span>모달에 등록된 모든 설정은 삭제됩니다.</span>
        </Content>
        <Content>
          <Dot />
          <span>
            삭제하는 경우 모달 순번은 하나씩 밀려 앱 화면에 노출될 수 있습니다.
          </span>
        </Content>
        <Button onClick={handleDelete}>
          <span>삭제하기</span>
        </Button>
      </Container>
    </PopUpContainer>
  );
};

const Container = styled.div`
  display: flex;
  width: 360px;
  padding: 24px 16px 20px 16px;
  flex-direction: column;
  /* align-items: center; */
  border-radius: 20px;
  background: var(--White, #fff);
`;

const Title = styled.span`
  margin: 0 auto 16px;
  color: ${({ theme }) => theme.colors.blueGray1000};
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.4px;
`;

const Description = styled.span`
  margin-left: 2px;
  color: ${({ theme }) => theme.colors.blueGray800};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
`;

const Button = styled.button`
  margin-top: 32px;
  padding: 8px 20px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.red1000};

  span {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Content = styled.div`
  display: flex;
  /* align-items: center; */
  margin-top: 12px;
  /* padding: 4px 8px; */

  span {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.blueGray700};
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.3px;
  }
`;

const Dot = () => {
  return (
    <div
      style={{
        width: 6,
        height: 6,
        display: "flex",
        marginTop: 8,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="6"
        viewBox="0 0 6 6"
        fill="none"
      >
        <circle cx="3" cy="3" r="3" fill="#CCCFD3" />
      </svg>
    </div>
  );
};
